import { all, put, takeLatest } from 'redux-saga/effects'
import {
  fetchBranchSuccess,
  fetchBranchFail, //get
  createBranchSuccess,
  createBranchFail, //post
  updateBranchSuccess,
  updateBranchFail, //update
  deleteBranchSuccess,
  deleteBranchFail, //delete
  updateBranchStateSuccess,
  getBranchByOidSuccess,
  getBranchByOidFail,
  getBranchAllSuccess,
  getBranchAllFail,
} from 'store/actions/branch'
import { EBranchTypes } from 'store/actionTypes'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'
import jwt_decode from 'jwt-decode'

const { methodGET, methodPOST } = requestManagement

interface Response {
  data: any
  message: string
  success: boolean
}

function* getBranch({ payload }: any) {
  //   console.log('getBranch :: ', payload)
  const { ...other } = payload
  const token = localStorage.getItem('token') || ''
  const decodedToken: any = jwt_decode(token)
  const { account_roles } = decodedToken
  try {
    const response: Response = yield methodGET({
      url: service.apiBranchs,
      param: {
        ...other,
        role_oid: account_roles.roles.oid,
      },
    })
    if (response.success) {
      yield put(fetchBranchSuccess({ ...response }))
    } else {
      yield put(fetchBranchFail({ error: response }))
    }
  } catch (e) {
    // console.log('getBranch catch :: ', e)
    yield put(fetchBranchFail({ error: e }))
  }
}

function* postBranch({ payload }: any) {
  const { open_time, close_time, ...other } = payload
  try {
    const response: Response = yield methodPOST({
      url: service.apiBranchs,
      data: {
        ...other,
      },
      param: {
        open_time,
        close_time,
      },
    })
    // console.log('postBranch response :: ', response)
    if (response.success) {
      yield put(createBranchSuccess({ branch: response }))
    } else {
      yield put(createBranchFail({ error: response }))
    }
  } catch (e) {
    yield put(createBranchFail({ error: e }))
  }
}

function* updateBranch({ payload }: any) {
  // console.log('updateBranch :: ', payload)
  const { oid, open_time, close_time, ...other } = payload
  try {
    const response: Response = yield methodPOST({
      url: service.apiBranchUpdate.replace(':oid', oid),
      param: {
        // ...other,
        oid,
        open_time,
        close_time,
      },
      data: {
        ...other,
      },
    })
    if (response.success) {
      yield put(updateBranchSuccess({ branch: response }))
    }
  } catch (e) {
    yield put(updateBranchFail({ error: e }))
  }
}

function* deleteBranch({ payload }: any) {
  //   console.log('deleteBranch :: ', payload)
  const { oid, ...other } = payload
  try {
    const response: Response = yield methodPOST({
      url: service.apiBranchDelete.replace(':oid', oid),
      param: {
        ...other,
        oid,
      },
    })
    yield put(deleteBranchSuccess({ branch: response.data }))
  } catch (e) {
    // console.log('deleteBranch catch :: ', e)
    yield put(deleteBranchFail({ error: e }))
  }
}

function* updateBranchState({ payload }: any) {
  const { ...other } = payload
  try {
    yield put(updateBranchStateSuccess({ ...other }))
  } catch (e) {}
}

function* getBranchByOid({ payload }: any) {
  const { oid, ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiBranchsId.replace(':oid', oid),
      param: {
        ...other,
        oid,
      },
    })
    if (response && response?.success) {
      yield put(getBranchByOidSuccess({ ...response }))
    } else {
      yield put(getBranchByOidFail({ error: response }))
    }
  } catch (e) {
    yield put(deleteBranchFail({ error: e }))
  }
}

function* getBranchAll({ payload }: any) {
  const { ...other } = payload
  const token = localStorage.getItem('token') || ''
  const company_oid = localStorage.getItem('company_id')
  const decodedToken: any = jwt_decode(token)
  const { account_roles } = decodedToken
  try {
    const response: Response = yield methodGET({
      url: service.apiBranchAll,
      param: {
        ...other,
        role_oid: account_roles.roles.oid,
        company_oid,
      },
    })
    if (response.success) {
      yield put(getBranchAllSuccess({ ...response }))
    } else {
      yield put(getBranchAllFail({ ...response }))
    }
  } catch (e) {}
}

function* branchSaga() {
  yield all([
    takeLatest(EBranchTypes.GET_BRANCH_REQUEST, getBranch),
    takeLatest(EBranchTypes.POST_BRANCH_REQUEST, postBranch),
    takeLatest(EBranchTypes.UPDATE_BRANCH_REQUEST, updateBranch),
    takeLatest(EBranchTypes.DELETE_BRANCH_REQUEST, deleteBranch),
    takeLatest(EBranchTypes.UPDATE_BRANCH_STATE_REQUEST, updateBranchState),
    takeLatest(EBranchTypes.GET_BRANCH_BY_ID_REQUEST, getBranchByOid),
    takeLatest(EBranchTypes.GET_BRANCH_ALL_REQUEST, getBranchAll),
  ])
}

export default branchSaga
