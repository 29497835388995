import withQuery from 'with-query'
import { useDispatch, useSelector } from 'react-redux'

const headers: any = {
  // 'Content-Language': 'en' as string,
  // 'Content-Type': 'application/json' as string,
  // Accept: 'application/json' as string,
  // 'x-client-signature': process.env.BACKEND_SIGNATURE as string,
  // 'x-client-token': '' as string,
  Authorization: '' as string,
  // 'set-cookie': '' as string,
  'Cache-Control': 'no-cache',
}
// import { DateTime } from 'luxon'
import { DateTime } from 'luxon'

const timeout = 25000

interface paramGet {
  url: string
  param?: any
  Authorization?: string
}

const methodGET = async ({ url, param, Authorization }: paramGet) => {
  //   console.log('methodGET :: ', { url, param, Authorization })
  // console.log('methodGET', localStorage.getItem('token'));

  //   if (Authorization) {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  //   }else{

  //   }
  // headers[
  //   'set-cookie'
  // ] = `auth-cookie=j%3A%7B%22token%22%3A%22eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOjEwMDAxLCJ0b2tlbl90eXBlIjoiQmVhcmVyIiwiYWNjb3VudFZlcnNpb24iOjQsImFjY291bnRfcm9sZXMiOnsiaWQiOjEsInJvbGVzIjp7ImlkIjoxLCJvaWQiOiIxIiwicm9sZV9uYW1lIjoic3VwZXJfYWRtaW4iLCJyb2xlX2Rlc2NyaXB0aW9uIjoiU3VwZXIgQWRtaW5pc3RyYXRvciJ9fSwiaWF0IjoxNjQzMzU2NDA0LCJleHAiOjE2NDM4NzQ4MDR9.luJv9pnqyH9dwcxUuoQpFlkXZG_pHSPUt2WpDQZ6Yl8%22%2C%22HttpOnly%22%3A%22Path%3D%2F%22%2C%22Max-Age%22%3A%226d%22%2C%22tokenType%22%3A%22Bearer%22%7D`

  //   console.log('headers : ', headers)
  const paramOption = {
    ...param,
    // timestamp: DateTime.local().valueOf(),
  }

  const makeUrl = withQuery(url, paramOption)

  const options = {
    method: 'GET',
    headers: headers,
    timeout: timeout,
    dataType: 'json',
  }
  //   console.log('[API] GET options :: ', options)

  const request = fetch(makeUrl, options)

  return await request
    .then(async (response) => {
      //   console.log(`[API] GET : ${makeUrl}`)
      const obj = {
        status: response.status,
        response: await response.json(),
      }
      return obj
    })
    .then((jsondata) => successHandler(jsondata))
    .catch((error) => console.log('[API] GET Failure', error))
}

const methodPOST = async ({
  url,
  data,
  token,
  upload,
  param,
  formData,
}: {
  url?: string
  data?: any
  token?: string
  upload?: any
  param?: any
  formData?: any
}) => {
  // if (token) {
  //   headers['x-client-token'] = token
  // }
  headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  if (data || param) {
    headers['Content-Type'] = 'application/json'
  }
  if (upload) {
    headers['Content-Type'] = 'multipart/form-data'
  }
  if (formData) {
    // headers['Content-Type'] = 'application/x-www-form-urlencoded'
    delete headers['Content-Type']
  }

  const paramOption = {
    ...param,
    // timestamp: DateTime.local().valueOf(),
  }
  const makeUrl = withQuery(url, paramOption)
  //   console.log('methodPOST :: ', { headers, data })
  const options = {
    method: 'POST',
    headers: headers,
    body: formData ? formData : JSON.stringify(data),
    timeout: timeout,
    dataType: 'json',
  }
  //   console.log('options', options)

  const request = fetch(makeUrl, options)

  return await request
    .then(async (response) => {
      // console.log(`[API] GET : ${makeUrl}`)
      const obj = {
        status: response.status,
        response: await response.json(),
      }
      return obj
    })
    .then((jsondata) => successHandler(jsondata))
    .catch((error) => console.log('[API] POST Failure', error))
}

const methodPUT = async ({
  url,
  data,
  token,
}: {
  url: string
  data: any
  token: string
}) => {
  //   console.log('optionsoptions', data)
  // if (token) {
  //   headers['x-client-token'] = token
  // }

  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data),
    timeout: timeout,
    dataType: 'json',
  }
  //   console.log('optionsoptions', options)
  const request = fetch(url, options)

  return await request
    .then(async (response) => {
      // console.log(`[API] GET : ${makeUrl}`)
      const obj = {
        status: response.status,
        response: await response.json(),
      }
      return obj
    })
    .then((jsondata) => successHandler(jsondata))
    .catch((error) => console.log('[API] PUT Failure', error))
}

const methodDELETE = async ({
  url,
  data,
  token,
}: {
  url: string
  data: any
  token: string
}) => {
  // if (token) {
  //   headers['x-client-token'] = token
  // }
  const paramOption = {
    timestamp: DateTime.local().valueOf(),
  }
  const makeUrl = withQuery(url, paramOption)
  const options = {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(data),
    timeout: timeout,
    dataType: 'json',
  }

  const request = fetch(makeUrl, options)

  return await request
    .then(async (response) => {
      // console.log(`[API] GET : ${makeUrl}`)
      const obj = {
        status: response.status,
        response: await response.json(),
      }
      return obj
    })
    .then((jsondata) => successHandler(jsondata))
    .catch((error) => console.log('[API] DELETE Failure', error))
}

const methodPATCH = async ({
  url,
  data,
  token,
}: {
  url: string
  data: any
  token: string
}) => {
  // if (token) {
  //   headers['x-client-token'] = token
  // }
  const paramOption = {
    timestamp: DateTime.local().valueOf(),
  }
  const makeUrl = withQuery(url, paramOption)
  const options = {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(data),
    timeout: timeout,
    dataType: 'json',
  }

  const request = fetch(makeUrl, options)

  return await request
    .then(async (response) => {
      // console.log(`[API] GET : ${makeUrl}`)
      const obj = {
        status: response.status,
        response: await response.json(),
      }
      return obj
    })
    .then((jsondata) => successHandler(jsondata))
    .catch((error) => console.log('[API] PATCH Failure', error))
}

function successHandler({ status, response }: { status: any; response: any }) {
  if (response.message === 'Unauthorized' && status === 401) {
    localStorage.clear()
  }
  return { success: true, status, ...response }
}

// function errorHandler (e) {
//   return {
//     success: false,
//     status: e.response.status,
//     data: e.response.body,
//     err: e.message,
//   }
// }

export default { methodGET, methodPOST, methodPUT, methodDELETE, methodPATCH }
