import '../styles/globals.less'
import type { AppProps } from 'next/app'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Wrapper from 'store'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const LayoutDynamic = dynamic(() => import('components/layout'), {
  ssr: false,
  loading: () => (
    <BoxStyled>
      <LoadingOutlined style={{ fontSize: 30, color: '#5DA6E7' }} spin />
    </BoxStyled>
  ),
})

function MyApp({ Component, pageProps }: AppProps) {
  const authentication: any = useSelector<any>((state) => state.authentication)
  const router = useRouter()

  useEffect(() => {
    if (authentication?.error) {
      localStorage.clear()
    }
  }, [authentication?.error])

  if (typeof window !== 'undefined') {
    if (router?.query && router.query.logout) {
      localStorage.clear()
    }

    const token = localStorage.getItem('token')

    if (token && router.pathname === '/login') {
      router.push('/home')
    }
    if (
      token === null &&
      router.pathname !== '/login' &&
      router.pathname !== '/register' &&
      router.pathname !== '/forget-password'
    ) {
      router.push('/login')
    }
    if (
      router &&
      (router.pathname === '/' || router.pathname === '') &&
      token !== null
    ) {
      router.push('/home')
    }
  }

  if (
    router &&
    (router.pathname === '/login' ||
      router.pathname === '/register' ||
      router.pathname === '/forget-password')
  ) {
    return <Component {...pageProps} />
  }

  return (
    <LayoutDynamic>
      <Component {...pageProps} />
    </LayoutDynamic>
  )
}

export default Wrapper.withRedux(MyApp)

const BoxStyled = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
