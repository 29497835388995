// import { EAuthenticationTypes } from 'store/actions'
import { IAuthenticationState, AuthenticationAction } from 'store/types'
import { EAuthenticationTypes } from 'store/actionTypes'

const initialState: IAuthenticationState = {
  login: {
    email: '',
    password: '',
    loading: false,
    dataLogin: {},
    errorStatus: false,
    dataNewLogin: {},
  },
  register: {
    username: '',
    email: '',
    password: '',
    secure_password: 0,
    loading: false,
    dataLogin: {},
  },
  forgetPassword: {
    email: '',
    secure_password: 0,
    password: '',
    confirm_password: '',
    tab: 0,
    data_pin: '',
    status: '',
    statusEmail: '',
  },
  error: null,
}

export default (state = initialState, action: AuthenticationAction) => {
  // console.log('AuthenticationAction reducer', state, action)

  switch (action.type) {
    case EAuthenticationTypes.POST_REGISTER:
      return { ...state, register: action.payload, error: null }
    case EAuthenticationTypes.STATUS_CHECK_EMAIL:
      state.forgetPassword.statusEmail = action.payload
      return { ...state, error: null }
    case EAuthenticationTypes.REGISTER_SUCCESS:
      state.register.loading = true
      state.register.dataLogin = action.payload
      return { ...state, error: null }
    case EAuthenticationTypes.POST_LOGIN:
      return { ...state, login: action.payload, error: null }
    case EAuthenticationTypes.LOGIN_SUCCESS:
      state.login.loading = true
      state.login.dataLogin = action.payload
      return { ...state, error: null }
    case EAuthenticationTypes.LOGIN_FAILED:
      // state.login.loading = false
      state.login.errorStatus = action.payload
      return { ...state }
    case EAuthenticationTypes.CHECK_EMAIL:
      return {
        ...state,
        forgetPassword: { ...state.forgetPassword, email: action.payload },
      }
    case EAuthenticationTypes.CHECK_PIN:
      return {
        ...state,
        forgetPassword: {
          ...state.forgetPassword,
          secure_password: action.payload,
        },
      }
    case EAuthenticationTypes.PIN_PASSWORD_STATUS:
      state.forgetPassword.data_pin = action.payload
      return { ...state }
    case EAuthenticationTypes.SET_TAB_NEW_PASSWORD:
      return {
        ...state,
        forgetPassword: { ...state.forgetPassword, tab: action.payload },
      }
    case EAuthenticationTypes.CLEAR_PIN:
      return {
        ...state,
        forgetPassword: { ...state.forgetPassword, data_pin: action.payload },
      }
    case EAuthenticationTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        forgetPassword: { ...state.forgetPassword, status: action.payload },
      }
    case EAuthenticationTypes.SET_NEW_REFRESH_TOKEN:
      return {
        ...state,
        login: { ...state.login, dataNewLogin: action.payload },
        error: null,
      }
    case EAuthenticationTypes.REFRESH_TOKEN_AUTHENTICATION_FAIL:
      return { ...state, error: action.payload.error }
    default:
      return { ...state }
  }
}
