import { EUserTypes } from 'store/actionTypes'
import { IUserState } from 'store/types'
import jwt_decode from 'jwt-decode'

const initialState: IUserState = {
  userlist: {
    currentPage: null,
    lastPage: null,
    perPage: null,
    total: null,
    dataLists: [],
  },
  errorStatusEmployee: '',
  errorEmployee: false,
  listRole: [],
  listAllRole: [],
  userById: {},
  statusDelete: false,
  loading: false,
  mode: '',
  statusError: '',
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case EUserTypes.CHECK_EMPLOYEE_ID:
      // console.log('action', action.payload);
      return { ...state }
    case EUserTypes.SET_ERROR_EMPLOYEE_ID:
      // console.log('SET_ERROR_EMPLOYEE_ID', action.payload);
      // state.errorEmployee
      return { ...state, errorEmployee: action.payload }
    case EUserTypes.FETCH_ROLE_BY_COMPANY:
      return { ...state }
    case EUserTypes.LIST_ROLE_BY_COMPANY:
      // console.log('action.payload', action.payload);

      state.listRole = action.payload.map((ac: any) => {
        return {
          label: ac.name,
          value: ac.oid,
            type: ac.level === null ? 'USER' : ac.level
        }
      })
      return { ...state }

    case EUserTypes.LIST_ALL_ROLE_IN_USER:
      const token: any = localStorage.getItem('token') || ''
      let decodedToken: any = {}
      if (token) {
        decodedToken = jwt_decode(token)
      }
      const list_all_role = action.payload.map((role: any, key: number) => {
        return {
          label: role.name,
          options:
            decodedToken?.account_roles?.roles?.name !== 'super_admin'
              ? role.roles
                  .map((ro: any, j: number) => {
                    return {
                      label: ro.name,
                      name: ro.name,
                      value: key + '_' + ro.oid,
                    }
                  })
                  .filter((p: any) => p.name !== 'super_admin')
              : role.roles.map((ro: any, j: number) => {
                  return {
                    label: ro.name,
                    name: ro.name,
                    value: key + '_' + ro.oid,
                  }
                }),
        }
      })
      // console.log('list_all_role', list_all_role, decodedToken?.account_roles?.roles?.name);

      state.listAllRole = list_all_role
      return { ...state }
    case EUserTypes.FETCH_LIST_USER_SUCCESS:
      const { dataLists, currentPage, perPage, ...other } = action.payload
      const objData = {
        ...other,
        dataLists: dataLists.map((data: any, key: number) => {
          var keys = currentPage * perPage - perPage
          return {
            key: keys + (key + 1),
            id: data.oid,
            username: data.username || '',
            firstname: data?.account_informations?.first_name || '',
            lastname: data?.account_informations?.last_name || '',
            role: data?.account_roles?.roles?.name || '',
            level: data?.account_roles?.roles?.level || '',
            email: data?.email || '',
            lastLogin: data?.last_login || '',
          }
        }),
      }
      return { ...state, userlist: objData }
    case EUserTypes.FETCH_USER_BY_ID_SUCCESS:
      return { ...state, userById: action.payload }
    case EUserTypes.DELETE_USER_SUCCESS:
      return { ...state, statusDelete: action.payload }
    case EUserTypes.ADD_USER_SUCCESS:
      state.loading = action.payload.success
      state.mode = action.payload.mode
      return { ...state, loading: action.payload }
    case EUserTypes.EDIT_USER_FAILED:
      state.statusError = action.payload
      return { ...state }
    default:
      return { ...state }
  }
}
