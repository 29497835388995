import { all, put, takeLatest } from 'redux-saga/effects'
import {
  getBranchGroupSuccess,
  getBranchGroupFail,
  postBranchGroupSuccess,
  postBranchGroupFail,
  getEditBranchGroupSuccess,
  getEditBranchGroupFail,
  deleteBranchGroupSuccess,
  deleteBranchGroupFail,
  updateBranchGroupStateSuccess,
} from 'store/actions'
import { EBranchGroupTypes } from 'store/actionTypes'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'

const { methodGET, methodPOST } = requestManagement

interface Response {
  data: any
  message: string
  success: boolean
}

function* getBranchGroup({ payload }: any) {
  const { ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiBranchGroup,
      param: {
        ...other,
      },
    })
      if (response.success) {
      yield put(getBranchGroupSuccess({ ...response }))
    } else {
      yield put(getBranchGroupFail({ ...response }))
    }
  } catch (e) {}
}

function* postBranchGroup({ payload }: any) {
  const { ...other } = payload
  const company_oid = localStorage.getItem('company_id')
  try {
    const response: Response = yield methodPOST({
      url: service.apiBranchGroup,
      data: {
        ...other,
        company_oid,
      },
    })
    if (response.success) {
      yield put(postBranchGroupSuccess({ ...response }))
    } else {
      yield put(postBranchGroupFail({ ...response }))
    }
  } catch (e) {}
}

function* getEditBranchGroup({ payload }: any) {
  const { branch_group_oid } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiEditBranchGroup.replace(':oid', branch_group_oid),
    })
    if (response.success) {
      yield put(getEditBranchGroupSuccess({ ...response }))
    } else {
      yield put(getEditBranchGroupFail({ ...response }))
    }
  } catch (e) {}
}

function* deleteBranchGroup({ payload }: any) {
  const { branch_group_oid } = payload
  try {
    const response: Response = yield methodPOST({
      url: service.apiDeleteBranchGroup.replace(':oid', branch_group_oid),
    })
    if (response.success) {
      yield put(deleteBranchGroupSuccess({ ...response }))
    } else {
      yield put(deleteBranchGroupFail({ ...response }))
    }
  } catch (e) {}
}

function* updateBranchGroupState({ payload }: any) {
  try {
    yield put(updateBranchGroupStateSuccess({ ...payload }))
  } catch (e) {}
}

function* branchGroupSaga() {
  yield all([
    takeLatest(EBranchGroupTypes.GET_BRANCH_GROUP_REQUEST, getBranchGroup),
    takeLatest(EBranchGroupTypes.POST_BRANCH_GROUP_REQUEST, postBranchGroup),
    takeLatest(
      EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_REQUEST,
      getEditBranchGroup
    ),
    takeLatest(
      EBranchGroupTypes.DELETE_BRANCH_GROUP_REQUEST,
      deleteBranchGroup
    ),
    takeLatest(
      EBranchGroupTypes.UPDATE_BRANCH_GROUP_STATE_REQUEST,
      updateBranchGroupState
    ),
  ])
}

export default branchGroupSaga
