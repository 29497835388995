import { ISiteAppState, TSiteAppActions } from 'store/types'
import { EMenuPermissionTypes } from 'store/actionTypes'
import _ from 'lodash'
const initialState: ISiteAppState = {
  //list all menu
  menuTabSiteApplication: [],

  siteAppData: [],
  //new list menu for update
  selectSiteApp: [],
  //all key in all menu
  checkKey: {},
  checkedKeyList: {},
  //list menu in each company
  menuList: {},
  loading: false,
  success: false,
  count: [false, false, false, false, false, false],
  chartSetting: {},
  failed: false,
}

//Get Permission List in each Menu Tab
const getMenuComponet = (data: any) => {
  let childrenList: any = []
  childrenList = data.map((list: any) => {
    const childrenList = list.is_feature
      .concat(list.is_child)
      .map((chList: any) => {
        return {
          title: chList.alias_name,
          key: chList.oid,
          name: chList.name,
        }
      })
    return {
      title: list.alias_name,
      name: list.name,
      key: list.oid,
      children: childrenList,
    }
  })

  //count because check sub level
  const count = childrenList.find((da: any) => {
    return da.children.length > 0
  })

  return {
    count: count ? true : false,
    dataList: [
      {
        title: 'Select All',
        key: '0',
        children: childrenList,
      },
    ],
  }
}

const getKey = (data: any) => {
  const checkListKey: any = {}
  let keys: any = []
  // console.log('state', state.siteAppData, state.menuTabSiteApplication);

  data.map((da: any) => {
    da.menu_permission_of_companies.map((me: any) => {
      if (me.is_child.length > 0) {
        //    arr = _.map(me.is_child, 'oid')
        me.is_child.map((ch: any) => {
          keys.push(ch.oid)
        })
        //    return arr
      } else if (me.is_feature.length > 0) {
        me.is_feature.map((ch: any) => {
          keys.push(ch.oid)
        })
      } else {
        keys.push(me.oid)
      }
    })
    // return menu
    checkListKey[da.menu_oid] = keys
    keys = []
  })
  return checkListKey
}

export default (state = initialState, action: TSiteAppActions) => {
  switch (action.type) {
    case EMenuPermissionTypes.SET_TAB_MENU_PERMISSION:
      state.menuTabSiteApplication = action.payload
      const menuComponent: any = {}
      const listMenu = action.payload.map((list: any, key: number) => {
        const menucom = getMenuComponet(list.menu_components)
        menuComponent[list.name] = menucom.dataList
        state.count[key] = menucom.count
        return menuComponent
      })
      state.menuList = listMenu[0]
      return { ...state }

    case EMenuPermissionTypes.SET_DATA_SITE_APPLICATION:
      //set checkKey in Tree Data
      state.checkKey = getKey(action.payload)
      return { ...state }

    case EMenuPermissionTypes.SET_SELECT_SITE_APPLICATION:
      // const checkSite: any = state.menuTabSiteApplication.find((site: any) => site.name === action.payload.menu.name)

      const checkData: any = action.payload?.menu?.menu_components
        .map((menu: any) => {
          const checkMenuComponent = action.payload.checkedKeysValue.find(
            (ac: any) => ac === menu.oid
          )

          if (checkMenuComponent) {
            return menu
          } else {
            if (
              menu.is_feature.length > 0 &&
              action.payload.menu.name === 'dashboard'
            ) {
              const subMenuData: any = menu.is_feature
                .map((submenu: any) => {
                  return submenu
                })
                .filter((p: any) =>
                  action.payload.checkedKeysValue.find(
                    (acSub: any) => acSub === p.oid
                  )
                )
              if (subMenuData.length > 0) {
                return {
                  ...menu,
                  is_feature: subMenuData,
                }
              }
            } else if (
              menu.is_child.length > 0 &&
              action.payload.menu.name !== 'dashboard'
            ) {
              const subMenuData: any = menu.is_child
                .map((submenu: any) => {
                  return submenu
                })
                .filter((p: any) =>
                  action.payload.checkedKeysValue.find(
                    (acSub: any) => acSub === p.oid
                  )
                )
              if (subMenuData.length > 0) {
                return {
                  ...menu,
                  is_child: subMenuData,
                }
              }
            }
          }
        })
        .filter((e: any) => e !== undefined)

      const data_permission: any = {
        menu_oid: action.payload.menu.oid,
        company_oid: action.payload.company_id,
        menu_permission_of_companies: checkData,
      }

      // console.log('check Site', data_permission, state.siteAppData.menu_oid, checkData);
      if (data_permission.menu_oid === state.siteAppData.menu_oid) {
        let tets = state.selectSiteApp.filter(
          (p: any) => p.menu_oid !== data_permission.menu_oid
        )
        tets.push(data_permission)
        // state.selectSiteApp.push(data_permission)
        state.selectSiteApp = tets
      } else {
        state.selectSiteApp.push(data_permission)
      }

      // console.log('same Menu OID', state.selectSiteApp);

      state.siteAppData = data_permission
      return { ...state }

    case EMenuPermissionTypes.UPDATE_MENU_PERMISSION_SUCCESS:
      state.success = action.payload
      return { ...state }
    case EMenuPermissionTypes.UPDATE_MENU_PERMISSION_FAILED:
      state.failed = action.payload
      return { ...state }
    case EMenuPermissionTypes.UPDATE_CHECKED_LIST:
      state.checkedKeyList = action.payload
      // console.log('UPDATE_CHECKED_LIST', action.payload)
      return { ...state }
    case EMenuPermissionTypes.SET_CHART_SETTING:
      return { ...state, chartSetting: action.payload }
    default:
      return { ...state }
  }
}
