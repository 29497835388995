import { IRole } from 'components/sections/user/mockData'
import { ERoleTypes } from 'store/actionTypes'
import { IFailPayload, IPayload } from 'store/types'
import {
  ICreateRolesFail,
  ICreateRolesRequest,
  ICreateRolesSuccess,
  IGetRolesAllFail,
  IGetRolesAllSuccess,
  IGetRolesCompanyAllFail,
  IGetRolesCompanyAllSuccess,
  IGetRolesCompanyOidOverviewRequest,
  IGetRolesCompanyOidOverviewSuccess,
  IGetRolesCompanyOidOverviewFail,
  IGetRolesFail,
  IGetRolesRequest,
  IGetRolesSuccess,
  IGetRoleSuccessPayload,
  IRoleAllResponse,
  IRoleCompanyAllResponse,
  IRoleDetail,
  IRolesCompaniesPermissionsParam,
  IPostRolesCompaniesPermissionsRequest,
  IPostRolesCompaniesPermissionsSuccess,
  IPostRolesCompaniesPermissionsFail,
  IPostsRolesCompaniesBranchsRequest,
  IPostsRolesCompaniesBranchsSuccess,
  IRolesCompaniesBranchsResponse,
  IPostsRolesCompaniesBranchsFail,
  ICreateRolesResponse,
  IRoleState,
  IUpdateRolesStateRequest,
  IUpdateRolesStateSuccess,
  IGetRoleByOidRequest,
  IGetRoleByOidResponse,
  IGetRoleByOidSuccess,
  IGetRoleByOidFail,
  IUpdateRoleRequest,
  IUpdateRoleSuccess,
  IUpdateRoleFail,
  IDeleteRoleRequest,
  IDeleteRoleSuccess,
  IDeleteRoleFail,
} from 'store/types/role.interface'

//Get
export const getRoleRequest = (payload: IPayload): IGetRolesRequest => ({
  type: ERoleTypes.GET_ROLE_REQUEST,
  payload,
})
export const getRoleSuccess = (
  payload: IGetRoleSuccessPayload
): IGetRolesSuccess => ({
  type: ERoleTypes.GET_ROLE_SUCCESS,
  payload,
})
export const getRoleFail = (payload: IFailPayload): IGetRolesFail => ({
  type: ERoleTypes.GET_ROLE_FAIL,
  payload,
})
//Get Role Company All
export const getRoleCompanyAllRequest = () => ({
  type: ERoleTypes.GET_ROLE_COMPANY_ALL_REQUEST,
})
export const getRoleCompanyAllSuccess = (
  payload: IRoleCompanyAllResponse
): IGetRolesCompanyAllSuccess => ({
  type: ERoleTypes.GET_ROLE_COMPANY_ALL_SUCCESS,
  payload,
})
export const getRoleCompanyAllFail = (
  payload: IFailPayload
): IGetRolesCompanyAllFail => ({
  type: ERoleTypes.GET_ROLE_COMPANY_ALL_FAIL,
  payload,
})
//Get Role All
export const getRoleAllRequest = () => ({
  type: ERoleTypes.GET_ROLE_ALL_REQUEST,
})
export const getRoleAllSuccess = (
  payload: IRoleAllResponse
): IGetRolesAllSuccess => ({
  type: ERoleTypes.GET_ROLE_ALL_SUCCESS,
  payload,
})
export const getRoleAllFail = (payload: IFailPayload): IGetRolesAllFail => ({
  type: ERoleTypes.GET_ROLE_ALL_FAIL,
  payload,
})
//Create
export const createRoleRequest = (
  payload: IRoleDetail
): ICreateRolesRequest => ({
  type: ERoleTypes.CREATE_ROLE_REQUEST,
  payload,
})
export const createRoleSuccess = (
  payload: ICreateRolesResponse
): ICreateRolesSuccess => ({
  type: ERoleTypes.CREATE_ROLE_SUCCESS,
  payload,
})
export const createRoleFail = (payload: IFailPayload): ICreateRolesFail => ({
  type: ERoleTypes.CREATE_ROLE_FAIL,
  payload,
})
//Get Role company by oid overview
export const getRolesCompanyByOidOverviewRequest = (
  payload: IPayload
): IGetRolesCompanyOidOverviewRequest => ({
  type: ERoleTypes.GET_ROLE_COMPANY_BY_ID_REQUEST,
  payload,
})
export const getRolesCompanyByOidOverviewSuccess = (
  payload: IGetRoleSuccessPayload
): IGetRolesCompanyOidOverviewSuccess => ({
  type: ERoleTypes.GET_ROLE_COMPANY_BY_ID_SUCCESS,
  payload,
})
export const getRolesCompanyBtOidOverviewFail = (
  payload: IFailPayload
): IGetRolesCompanyOidOverviewFail => ({
  type: ERoleTypes.GET_ROLE_COMPANY_BY_ID_FAIL,
  payload,
})
//Post Roles Companies Permissions
export const postRolesCompaniesPermissionsRequest = (
  payload: IRolesCompaniesPermissionsParam
): IPostRolesCompaniesPermissionsRequest => ({
  type: ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_REQUEST,
  payload,
})
export const postRolesCompaniesPermissionsSuccess = (
  payload: any
): IPostRolesCompaniesPermissionsSuccess => ({
  type: ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_SUCCESS,
  payload,
})
export const postRolesCompaniesPermissionsFail = (
  payload: IFailPayload
): IPostRolesCompaniesPermissionsFail => ({
  type: ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_FAIL,
  payload,
})
//Post Roles Companies Branchs
export const postRolesCompaniesBranchRequest = (
  payload: IRolesCompaniesPermissionsParam
): IPostsRolesCompaniesBranchsRequest => ({
  type: ERoleTypes.POST_ROLES_COMPANIES_BRANCH_REQUEST,
  payload,
})
export const postRolesCompaniesBranchSuccess = (
  payload: IRolesCompaniesBranchsResponse
): IPostsRolesCompaniesBranchsSuccess => ({
  type: ERoleTypes.POST_ROLES_COMPANIES_BRANCH_SUCCESS,
  payload,
})
export const postRolesCompaniesBranchFail = (
  payload: IFailPayload
): IPostsRolesCompaniesBranchsFail => ({
  type: ERoleTypes.POST_ROLES_COMPANIES_BRANCH_FAIL,
  payload,
})
//Update State
export const updateRoleStateRequest = (
  payload: IRoleState
): IUpdateRolesStateRequest => ({
  type: ERoleTypes.UPDATE_ROLES_STATE_REQUEST,
  payload,
})
export const updateRoleStateSuccess = (
  payload: IRoleState
): IUpdateRolesStateSuccess => ({
  type: ERoleTypes.UPDATE_ROLES_STATE_SUCCESS,
  payload,
})
//Get Role By oid
export const getRoleByOidRequest = (
  payload: IPayload
): IGetRoleByOidRequest => ({
  type: ERoleTypes.GET_ROLE_BY_OID_REQUEST,
  payload,
})
export const getRoleByOidSuccess = (
  payload: IGetRoleByOidResponse
): IGetRoleByOidSuccess => ({
  type: ERoleTypes.GET_ROLE_BY_OID_SUCCESS,
  payload,
})
export const getRoleByOidFail = (payload: IFailPayload): IGetRoleByOidFail => ({
  type: ERoleTypes.GET_ROLE_BY_OID_FAIL,
  payload,
})
//Update Role
export const updateRoleRequest = (
  payload: IRoleDetail
): IUpdateRoleRequest => ({
  type: ERoleTypes.UPDATE_ROLE_REQUEST,
  payload,
})
export const updateRoleSuccess = (payload: any): IUpdateRoleSuccess => ({
  type: ERoleTypes.UPDATE_ROLE_SUCCESS,
  payload,
})
export const updateRoleFail = (payload: IFailPayload): IUpdateRoleFail => ({
  type: ERoleTypes.UPDATE_ROLE_FAIL,
  payload,
})
//Delete Role
export const deleteRoleRequest = (payload: IPayload): IDeleteRoleRequest => ({
  type: ERoleTypes.DELETE_ROLE_REQUEST,
  payload,
})
export const deleteRoleSuccess = (payload: any): IDeleteRoleSuccess => ({
  type: ERoleTypes.DELETE_ROLE_SUCCESS,
  payload,
})
export const deleteRoleFail = (payload: any): IDeleteRoleFail => ({
  type: ERoleTypes.DELETE_ROLE_FAIL,
  payload,
})
