import { all, fork } from 'redux-saga/effects'

import branchSaga from './branch'
import companySaga from './company'
import siteAppSaga from './siteApp'
import userSaga from './user'
import authenSaga from './authentication'
import masterDataSaga from './masterData'
import roleSage from './role'
import menuSaga from './menu'
import branchGroupSaga from './branchGroup'

export function* rootSaga() {
  try {
    yield all([fork(branchSaga)])
    yield all([fork(companySaga)])
    yield all([fork(siteAppSaga)])
    yield all([fork(authenSaga)])
    yield all([fork(masterDataSaga)])
    yield all([fork(roleSage)])
    yield all([fork(userSaga)])
    yield all([fork(menuSaga)])
    yield all([fork(branchGroupSaga)])
  } catch (error) {
    console.log('rootSaga error :: ', error)
  }
}
