import { all, put, takeLatest } from 'redux-saga/effects'
import {
  fetchCountrySuccess,
  fetchCompanyFail,
  fetchZipCodeSuccess,
  fetchZipCodeFail,
} from 'store/actions'
import { fetchProvinceSuccess, fetchProvinceFail } from 'store/actions'
import { fetchDistrictSuccess, fetchDistrictFail } from 'store/actions'
import { fetchSubDistrictSuccess, fetchSubDistrictFail } from 'store/actions'
import { EMasterDataTypes } from 'store/actionTypes'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'

const { methodGET } = requestManagement

interface Response {
  data: any
  message: string
  success: boolean
}

function* getCountry({ payload }: any) {
  //   console.log('getCountry :: ', payload)
  const { ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiMasterDataCountry,
      param: {
        ...other,
      },
    })
    yield put(fetchCountrySuccess({ country: response.data }))
  } catch (e) {
    // console.log('getCountry catch :: ', e)
    yield put(fetchCompanyFail({ error: e }))
  }
}

function* getProvince({ payload }: any) {
  //   console.log('getProvince :: ', payload)
  const { country_id, ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiMasterDataProvince.replace(':country_id', country_id),
      param: {
        ...other,
      },
    })
    // console.log('getProvince response :: ', response)
    yield put(fetchProvinceSuccess({ province: response.data }))
  } catch (e) {
    // console.log('getProvince catch :: ', e)
    yield put(fetchProvinceFail({ error: e }))
  }
}

function* getDistrict({ payload }: any) {
  //   console.log('getDistrict :: ', payload)
  const { ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiMasterDataDistrict.replace(
        ':province_id',
        other.province_id
      ),
      param: {
        ...other,
      },
    })
    yield put(fetchDistrictSuccess({ district: response.data }))
  } catch (e) {
    // console.log('getDistrict catch :: ', e)
    yield put(fetchDistrictFail({ error: e }))
  }
}

function* getSubDistrict({ payload }: any) {
  //   console.log('getSubDistrict :: ', payload)
  const { ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiMasterDataSubDistrict.replace(
        ':district_id',
        other.district_id
      ),
      param: {
        ...other,
      },
    })
    yield put(fetchSubDistrictSuccess({ subDistrict: response.data }))
  } catch (e) {
    // console.log('getSubDistrict catch :: ', e)
    yield put(fetchSubDistrictFail({ error: e }))
  }
}

function* getZipCode({ payload }: any) {
  const { zipCode, ...other } = payload
  try {
    const response: Response = yield methodGET({
      url: service.apiMasterDataZipCode.replace(':zipcode', zipCode),
      param: {
        ...other,
      },
    })
    if (response.success) {
      yield put(fetchZipCodeSuccess({ ...response }))
    } else {
      yield put(fetchZipCodeFail({ error: response }))
    }
  } catch (e) {
    // console.log('getZipCode e :: ', e)
  }
}

function* masterSaga() {
  yield all([
    takeLatest(EMasterDataTypes.GET_COUNTRY_REQUEST, getCountry),
    takeLatest(EMasterDataTypes.GET_PROVINCE_REQUEST, getProvince),
    takeLatest(EMasterDataTypes.GET_DISTRICT_REQUEST, getDistrict),
    takeLatest(EMasterDataTypes.GET_SUB_DISTRICT_REQUEST, getSubDistrict),
    takeLatest(EMasterDataTypes.GET_ZIP_CODE_REQUEST, getZipCode),
  ])
}

export default masterSaga
