import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createWrapper } from 'next-redux-wrapper'

import rootReducer from './reducers'
import { rootSaga } from './sagas/rootSaga'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    __REDUX_DEVTOOLS_EXTENSION__: any
  }
}

const makeStore: any = () => {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  const store = createStore(
    rootReducer,
    process.env.NODE_ENV === 'development'
      ? composeEnhancers(applyMiddleware(sagaMiddleware))
      : applyMiddleware(sagaMiddleware)
  )
  sagaMiddleware.run(rootSaga)

  return store
}
const store = createWrapper(makeStore, { debug: false })

export default store
