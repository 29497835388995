export enum EAuthenticationTypes {
  POST_LOGIN = 'POST_LOGIN',
  POST_REGISTER = 'POST_REGISTER',
  CHECK_EMAIL = 'CHECK_EMAIL',
  CHECK_PIN = 'CHECK_PIN',
  POST_NEW_PASSWORD = 'POST_NEW_PASSWORD',
  SET_TAB_NEW_PASSWORD = 'SET_TAB_NEW_PASSWORD',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  PIN_PASSWORD_STATUS = 'PIN_PASSWORD_STATUS',
  CLEAR_PIN = 'CLEAR_PIN',
  RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',
  STATUS_CHECK_EMAIL = 'STATUS_CHECK_EMAIL',
  REFRESH_TOKEN_AUTHENTICATION = 'REFRESH_TOKEN_AUTHENTICATION',
  REFRESH_TOKEN_AUTHENTICATION_FAIL = 'REFRESH_TOKEN_AUTHENTICATION_FAIL',
  SET_NEW_REFRESH_TOKEN = 'SET_NEW_REFRESH_TOKEN',
}
export enum ECompanyTypes {
  //get company by role
  GET_COMPANY_BY_ROLE_REQUEST = 'GET_COMPANY_BY_ROLE_REQUEST',
  GET_COMPANY_BY_ROLE_SUCCESS = 'GET_COMPANY_BY_ROLE_SUCCESS',
  GET_COMPANY_BY_ROLE_FAIL = 'GET_COMPANY_BY_ROLE_FAIL',
  //select company
  SELECT_COMPANY_REQUEST = 'SELECT_COMPANY_REQUEST',
  SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS',
  //update company
  UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE',
  //Post Company
  POST_COMPANY_REQUEST = 'POST_COMPANY_REQUEST',
  POST_COMPANY_SUCCESS = 'POST_COMPANY_SUCCESS',
  POST_COMPANY_FAIL = 'POST_COMPANY_FAIL',
  //Media logo
  POST_MEDIA_COMPANY_LOGO_REQUEST = 'POST_MEDIA_COMPANY_LOGO_REQUEST',
  POST_MEDIA_COMPANY_LOGO_SUCCESS = 'POST_MEDIA_COMPANY_LOGO_SUCCESS',
  POST_MEDIA_COMPANY_LOGO_FAIL = 'POST_MEDIA_COMPANY_LOGO_FAIL',
  //Media logo sidebar
  POST_MEDIA_COMPANY_LOGO_SIDE_REQUEST = 'POST_MEDIA_COMPANY_LOGO_SIDE_REQUEST',
  POST_MEDIA_COMPANY_LOGO_SIDE_SUCCESS = 'POST_MEDIA_COMPANY_LOGO_SIDE_SUCCESS',
  POST_MEDIA_COMPANY_LOGO_SIDE_FAIL = 'POST_MEDIA_COMPANY_LOGO_SIDE_FAIL',
  //State
  UPDATE_COMPANY_STATE_REQUEST = 'UPDATE_COMPANY_STATE_REQUEST',
  UPDATE_COMPANY_STATE_SUCCESS = 'UPDATE_COMPANY_STATE_SUCCESS',
  CLEAR_COMPANY_SELECT = 'CLEAR_COMPANY_SELECT',
  //get company
  GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAIL = 'GET_COMPANY_FAIL',
  //Get company by oid
  GET_COMPANY_BY_OID_REQUEST = 'GET_COMPANY_BY_OID_REQUEST',
  GET_COMPANY_BY_OID_SUCCESS = 'GET_COMPANY_BY_OID_SUCCESS',
  GET_COMPANY_BY_OID_FAIL = 'GET_COMPANY_BY_OID_FAIL',
  //Get company by user
  GET_COMPANIES_BY_USER_REQUEST = 'GET_COMPANIES_BY_USER_REQUEST',
  GET_COMPANIES_BY_USER_SUCCESS = 'GET_COMPANIES_BY_USER_SUCCESS',
  GET_COMPANIES_BY_USER_FAIL = 'GET_COMPANIES_BY_USER_FAIL',
}

export enum EMenuPermissionTypes {
  FETCH_MENU_PERMISSION = 'FETCH_MENU_PERMISSION',
  SET_TAB_MENU_PERMISSION = 'SET_TAB_MENU_PERMISSION',
  UPDATE_SITE_APPLICATION = 'UPDATE_SITE_APPLICATION',
  FETCH_SITE_APPLICATION = 'FETCH_SITE_APPLICATION',
  SET_DATA_SITE_APPLICATION = 'SET_DATA_SITE_APPLICATION',
  SET_SELECT_SITE_APPLICATION = 'SET_SELECT_SITE_APPLICATION',
  CLEAR_CHECK_KEYS = 'CLEAR_CHECK_KEYS',
  UPDATE_MENU_PERMISSION_SUCCESS = 'UPDATE_MENU_PERMISSION_SUCCESS',
  UPDATE_MENU_PERMISSION_FAILED = 'UPDATE_MENU_PERMISSION_FAILED',
  UPDATE_CHECKED_LIST = 'UPDATE_CHECKED_LIST',
  UPDATE_CHART_SETTING = 'UPDATE_CHART_SETTING',
  FETCH_CHART_SETTING = 'FETCH_CHART_SETTING',
  SET_CHART_SETTING = 'SET_CHART_SETTING',
}

export enum EUserTypes {
  FETCH_LIST_USER = 'FETCH_LIST_USER',
  FETCH_LIST_USER_BY_COMPANY = 'FETCH_LIST_USER_BY_COMPANY',
  FETCH_USER_BY_ID = 'FETCH_USER_BY_ID',
  FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS',
  FETCH_LIST_USER_SUCCESS = 'FETCH_LIST_USER_SUCCESS',
  ADD_USER_REQUEST = 'ADD_USER_REQUEST',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  CHECK_EMPLOYEE_ID = 'CHECK_EMPLOYEE_ID',
  SET_ERROR_EMPLOYEE_ID = 'SET_ERROR_EMPLOYEE_ID',
  FETCH_ROLE_BY_COMPANY = 'FETCH_ROLE_BY_COMPANY',
  LIST_ROLE_BY_COMPANY = 'LIST_ROLE_BY_COMPANY',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  LIST_ALL_ROLE_IN_USER = 'LIST_ALL_ROLE_IN_USER',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  EDIT_USER_FAILED = 'EDIT_USER_FAILED',
}

export enum EBranchTypes {
  GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST',
  GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS',
  GET_BRANCH_FAIL = 'GET_BRANCH_FAIL',

  GET_BRANCH_BY_ID_REQUEST = 'GET_BRANCH_BY_ID_REQUEST',
  GET_BRANCH_BY_ID_SUCCESS = 'GET_BRANCH_BY_ID_SUCCESS',
  GET_BRANCH_BY_ID_FAIL = 'GET_BRANCH_BY_ID_FAIL',

  POST_BRANCH_REQUEST = 'POST_BRANCH_REQUEST',
  POST_BRANCH_SUCCESS = 'POST_BRANCH_SUCCESS',
  POST_BRANCH_FAIL = 'POST_BRANCH_FAIL',

  UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST',
  UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS',
  UPDATE_BRANCH_FAIL = 'UPDATE_BRANCH_FAIL',

  DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST',
  DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS',
  DELETE_BRANCH_FAIL = 'DELETE_BRANCH_FAIL',

  UPDATE_BRANCH_STATE_REQUEST = 'UPDATE_BRANCH_STATE_REQUEST',
  UPDATE_BRANCH_STATE_SUCCESS = 'UPDATE_BRANCH_STATE_SUCCESS',
  UPDATE_BRANCH_STATE_FAIL = 'UPDATE_BRANCH_STATE_FAIL',

  GET_BRANCH_ALL_REQUEST = 'GET_BRANCH_ALL_REQUEST',
  GET_BRANCH_ALL_SUCCESS = 'GET_BRANCH_ALL_SUCCESS',
  GET_BRANCH_ALL_FAIL = 'GET_BRANCH_ALL_FAIL',
}

export enum EMasterDataTypes {
  GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL',

  GET_PROVINCE_REQUEST = 'GET_PROVINCE_REQUEST',
  GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS',
  GET_PROVINCE_FAIL = 'GET_PROVINCE_FAIL',

  GET_DISTRICT_REQUEST = 'GET_DISTRICT_REQUEST',
  GET_DISTRICT_SUCCESS = 'GET_DISTRICT_SUCCESS',
  GET_DISTRICT_FAIL = 'GET_DISTRICT_FAIL',

  GET_SUB_DISTRICT_REQUEST = 'GET_SUB_DISTRICT_REQUEST',
  GET_SUB_DISTRICT_SUCCESS = 'GET_SUB_DISTRICT_SUCCESS',
  GET_SUB_DISTRICT_FAIL = 'GET_SUB_DISTRICT_FAIL',

  GET_ZIP_CODE_REQUEST = 'GET_ZIP_CODE_REQUEST',
  GET_ZIP_CODE_SUCCESS = 'GET_ZIP_CODE_SUCCESS',
  GET_ZIP_CODE_FAIL = 'GET_ZIP_CODE_FAIL',
}

export enum ERoleTypes {
  GET_ROLE_REQUEST = 'GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS',
  GET_ROLE_FAIL = 'GET_ROLE_FAIL',

  GET_ROLE_ALL_REQUEST = 'GET_ROLE_ALL_REQUEST',
  GET_ROLE_ALL_SUCCESS = 'GET_ROLE_ALL_SUCCESS',
  GET_ROLE_ALL_FAIL = 'GET_ROLE_ALL_FAIL',

  GET_ROLE_COMPANY_ALL_REQUEST = 'GET_ROLE_COMPANY_ALL_REQUEST',
  GET_ROLE_COMPANY_ALL_SUCCESS = 'GET_ROLE_COMPANY_ALL_SUCCESS',
  GET_ROLE_COMPANY_ALL_FAIL = 'GET_ROLE_COMPANY_ALL_FAIL',

  GET_ROLE_COMPANY_BY_ID_REQUEST = 'GET_ROLE_COMPANY_BY_ID_REQUEST',
  GET_ROLE_COMPANY_BY_ID_SUCCESS = 'GET_ROLE_COMPANY_BY_ID_SUCCESS',
  GET_ROLE_COMPANY_BY_ID_FAIL = 'GET_ROLE_COMPANY_BY_ID_FAIL',

  CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL',

  UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL',

  DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL',

  POST_ROLES_COMPANIES_PERMISSIONS_REQUEST = 'POST_ROLES_COMPANIES_PERMISSIONS_REQUEST',
  POST_ROLES_COMPANIES_PERMISSIONS_SUCCESS = 'POST_ROLES_COMPANIES_PERMISSIONS_SUCCESS',
  POST_ROLES_COMPANIES_PERMISSIONS_FAIL = 'POST_ROLES_COMPANIES_PERMISSIONS_FAIL',

  POST_ROLES_COMPANIES_BRANCH_REQUEST = 'POST_ROLES_COMPANIES_BRANCH_REQUEST',
  POST_ROLES_COMPANIES_BRANCH_SUCCESS = 'POST_ROLES_COMPANIES_BRANCH_SUCCESS',
  POST_ROLES_COMPANIES_BRANCH_FAIL = 'POST_ROLES_COMPANIES_BRANCH_FAIL',

  UPDATE_ROLES_STATE_REQUEST = 'UPDATE_ROLES_STATE_REQUEST',
  UPDATE_ROLES_STATE_SUCCESS = 'UPDATE_ROLES_STATE_SUCCESS',

  GET_ROLE_BY_OID_REQUEST = 'GET_ROLE_BY_OID_REQUEST',
  GET_ROLE_BY_OID_SUCCESS = 'GET_ROLE_BY_OID_SUCCESS',
  GET_ROLE_BY_OID_FAIL = 'GET_ROLE_BY_OID_FAIL',
}

export enum EMenuTypes {
  GET_MENU_REQUEST = 'GET_MENU_REQUEST',
  GET_MENU_SUCCESS = 'GET_MENU_SUCCESS',
  GET_MENU_FAIL = 'GET_MENU_FAIL',
}

export enum EBranchGroupTypes {
  GET_BRANCH_GROUP_REQUEST = 'GET_BRANCH_GROUP_REQUEST',
  GET_BRANCH_GROUP_SUCCESS = 'GET_BRANCH_GROUP_SUCCESS',
  GET_BRANCH_GROUP_FAIL = 'GET_BRANCH_GROUP_FAIL',

  GET_EDIT_BRANCH_GROUP_REQUEST = 'GET_EDIT_BRANCH_GROUP_REQUEST',
  GET_EDIT_BRANCH_GROUP_SUCCESS = 'GET_EDIT_BRANCH_GROUP_SUCCESS',
  GET_EDIT_BRANCH_GROUP_FAIL = 'GET_EDIT_BRANCH_GROUP_FAIL',

  POST_BRANCH_GROUP_REQUEST = 'POST_BRANCH_GROUP_REQUEST',
  POST_BRANCH_GROUP_SUCCESS = 'POST_BRANCH_GROUP_SUCCESS',
  POST_BRANCH_GROUP_FAIL = 'POST_BRANCH_GROUP_FAIL',

  DELETE_BRANCH_GROUP_REQUEST = 'DELETE_BRANCH_GROUP_REQUEST',
  DELETE_BRANCH_GROUP_SUCCESS = 'DELETE_BRANCH_GROUP_SUCCESS',
  DELETE_BRANCH_GROUP_FAIL = 'DELETE_BRANCH_GROUP_FAIL',

  UPDATE_BRANCH_GROUP_STATE_REQUEST = 'UPDATE_BRANCH_GROUP_STATE_REQUEST',
  UPDATE_BRANCH_GROUP_STATE_SUCCESS = 'UPDATE_BRANCH_GROUP_STATE_SUCCESS',
}
