import requestManagement from 'store/actions/requestManagement'
import { EAuthenticationTypes } from 'store/actionTypes'
import { all, put, takeLatest, select } from 'redux-saga/effects'
import Router, { useRouter } from 'next/router'
import {
  setTabForgetPassword,
  loginSuccess,
  registerSuccess,
  pinPasswordStatus,
  setStatusMessage,
  setNewRefreshToken,
  loginFailed,
  refreshTokenFail,
} from 'store/actions'
import {
  RegisterResponse,
  IStatusAuthentication,
  IForgetPasswordState,
  IStatusPinPassword,
} from 'store/types'
import service from 'store/service'
import { Helper } from 'utils/helper'
import jwt_decode from 'jwt-decode'

const { methodPOST, methodGET } = requestManagement
function* Register({ payload }: any) {
  const objData = {
    username: payload.username,
    password: Helper.cryptoUtil(payload.password),
    email: payload.email,
    secure_password: Helper.cryptoUtil(payload.secure_password),
  }

  try {
    const response: IStatusAuthentication = yield methodPOST({
      url: service.register,
      data: objData,
    })
    if (response.success) {
      yield put(registerSuccess(response.data))
    }
  } catch (e) {}
}

function* Login({ payload }: any) {
  let decodedToken: any = {}

  const objData = {
    email: payload.email,
    // password: payload.password
    password: Helper.cryptoUtil(payload.password),
  }
  try {
    const response: IStatusAuthentication = yield methodPOST({
      url: service.login,
      data: objData,
    })

    if (response.success) {
      const token: string = response?.data?.accessToken?.token as string
      decodedToken = jwt_decode(token)
      yield put(loginSuccess(response.data))
      // localStorage.setItem('token', token)
      localStorage.setItem('oid', response?.data?.oid)
      localStorage.setItem('email', response?.data?.email)
      localStorage.setItem('token', token)
      localStorage.setItem('time', new Date().getTime().toString())
      localStorage.setItem('level', decodedToken?.account_roles?.roles?.level)
      yield put(loginFailed(false))
    } else {
      yield put(loginFailed(true))
    }
  } catch (e) {
    console.log('Login catch :: ', e)
  }
}

function* CheckEmail({ payload }: any) {
  try {
    const response: IStatusAuthentication = yield methodPOST({
      url: service.checkMail,
      data: payload,
    })

    if (response.success) {
      yield put(setTabForgetPassword(1))
      // yield put(setStatusMessage(response.success))
    } else {
      yield put(setStatusMessage('Email is not found in system'))
    }
  } catch (e) {}
}

function* CheckPin({ payload }: any) {
  const state: IForgetPasswordState = yield select(
    (root: any) => root.authentication.forgetPassword.email
  )
  const objData = {
    email: state.email,
    secure_password: Helper.cryptoUtil(payload),
  }

  try {
    const response: IStatusPinPassword = yield methodPOST({
      url: service.checkPin,
      data: objData,
    })

    if (response.success) {
      yield put(setTabForgetPassword(2))
    } else {
      yield put(pinPasswordStatus(response.error_code))
    }
  } catch (e) {}
}

function* ResetPassword({ payload }: any) {
  const state: IForgetPasswordState = yield select((root: any) => ({
    email: root.authentication.forgetPassword.email.email,
    secure_password: Helper.cryptoUtil(
      root.authentication.forgetPassword.secure_password
    ),
  }))
  const objData = {
    // email: payload.email,
    // secure_password: payload.secure_password,
    confirm_password: Helper.cryptoUtil(payload.confirm_password),
    new_password: Helper.cryptoUtil(payload.new_password),
  }
  try {
    const response: IStatusAuthentication = yield methodPOST({
      url: service.resetPassword,
      data: { ...state, ...objData },
    })
    yield put(setTabForgetPassword(3))
  } catch (e) {}
}

function* RefreshToken() {
  try {
    const response: IStatusAuthentication = yield methodGET({
      url: service.refreshToken,
    })
    // yield put(setNewRefreshToken(response.data))
    if (response.success) {
      const token: string = response?.data?.accessToken?.token as string
      yield put(setNewRefreshToken(response.data))
      localStorage.setItem('token', token)
      localStorage.setItem('time', new Date().getTime().toString())
      // yield put(loginFailed(false))
    } else {
      yield put(refreshTokenFail({ error: response }))
    }
    // if (response.success) {
    //     yield put(setTabForgetPassword(2))
    // } else {
    //     yield put(pinPasswordStatus(response.error_code))
    // }
  } catch (e) {
    yield put(refreshTokenFail({ error: e }))
  }
}
function* authenSaga() {
  yield all([takeLatest(EAuthenticationTypes.POST_REGISTER, Register)])
  yield all([takeLatest(EAuthenticationTypes.POST_LOGIN, Login)])
  yield all([takeLatest(EAuthenticationTypes.CHECK_EMAIL, CheckEmail)])
  yield all([takeLatest(EAuthenticationTypes.CHECK_PIN, CheckPin)])
  yield all([takeLatest(EAuthenticationTypes.POST_NEW_PASSWORD, ResetPassword)])
  yield all([
    takeLatest(EAuthenticationTypes.REFRESH_TOKEN_AUTHENTICATION, RefreshToken),
  ])
}

export default authenSaga
