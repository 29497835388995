import { all, put, takeLatest } from 'redux-saga/effects'
import {
  fetchCompanySuccess,
  fetchCompanyFail,
  selectCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  //create
  createCompanySuccess,
  createCompanyFail,
  //Media
  uploadMediaCompanyLogoSuccess,
  // updateMediaCompanyLogoFail,
  uploadMediaCompanyLogoSideSuccess,
  //State
  updateCompanyStateSuccess,
  getCompaniesSuccess,
  getCompaniesFail,
  getCompanyByOidFail,
  getCompanyByOidSuccess,
  getCompaniesByUserFail,
  getCompaniesByUserSuccess,
} from 'store/actions'
import { ECompanyTypes } from 'store/actionTypes'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'
import jwt_decode from 'jwt-decode'

const { methodGET, methodPOST } = requestManagement

interface ResponseCompany {
  data: any
  message: string
  success: boolean
  status?: number
}

function* getCompanyByRole({ payload }: any) {
  const { isRole, ...other } = payload
  //   console.log('getCompanyByRole :: ', payload)
  try {
    const token = localStorage.getItem('token') || ''
    const decodedToken: any = jwt_decode(token)
    const { account_roles } = decodedToken
    const response: ResponseCompany = yield methodGET({
      url: service.apiCompaniesByRole.replace(':oid', account_roles.roles.oid),
      param: {
        ...other,
      },
    })
    if (response.success) {
      yield put(fetchCompanySuccess({ company: response.data, isRole }))
    } else {
      yield put(fetchCompanyFail({ error: response }))
    }
  } catch (e) {
    // console.log('getCompanyByRole catch :: ', e)
    yield put(fetchCompanyFail({ error: e }))
  }
}

function* postCompany({ payload }: any) {
  const { ...other } = payload
  //   console.log('postCompany :: ', payload)
  try {
    const response: ResponseCompany = yield methodPOST({
      url: service.apiCompanies,
      data: {
        ...other,
      },
    })
    // console.log('postCompany response :: ', response)
    if (response.success) {
      yield put(createCompanySuccess({ response: response }))
    } else {
      yield put(createCompanyFail({ error: response }))
    }
  } catch (e) {
    yield put(createCompanyFail({ error: e }))
  }
}

function* SelectCompany({ payload }: any) {
  const { ...other } = payload
  try {
    yield put(selectCompanySuccess({ ...other }))
  } catch (e) {
    // console.log('SelectCompany catch :: ', e)
  }
}

function* UpdateCompany({ payload }: any) {
  // console.log('UpdateCompany :: payload ', payload)
  const { oid, ...other } = payload
  try {
    const response: ResponseCompany = yield methodPOST({
      url: service.apiCompaniesUpdate.replace(':oid', oid),
      param: {
        oid,
      },
      data: {
        oid,
        ...other,
      },
    })
    // console.log('UpdateCompany :: response ', response)
    if (response.success) {
      yield put(updateCompanySuccess({ response }))
    } else {
      yield put(updateCompanyFail({ error: response }))
    }
  } catch (e) {
    // console.log('UpdateCompany catch :: ', e)
    yield put(updateCompanyFail({ error: e }))
  }
}

function* UploadLogo({ payload }: any) {
  const { oid, file } = payload
  let formData = new FormData()
  formData.append('file', file)
  try {
    const response: ResponseCompany = yield methodPOST({
      url: service.apiMediaCompanyLogo.replace(':oid', oid),
      formData,
    })
    yield put(uploadMediaCompanyLogoSuccess({ ...response }))
  } catch (e) {
    // yield put(updateMediaCompanyLogoFail({ error: e }))
  }
}

function* UploadLogoSide({ payload }: any) {
  const { oid, file } = payload
  let formData = new FormData()
  formData.append('file', file)
  try {
    const response: ResponseCompany = yield methodPOST({
      url: service.apiMediaCompanyLogoSide.replace(':oid', oid),
      formData,
    })
    yield put(uploadMediaCompanyLogoSideSuccess({ ...response }))
  } catch (e) {
    // yield put(updateMediaCompanyLogoFail({ error: e }))
  }
}

function* UpdateCompanyState({ payload }: any) {
  const { ...other } = payload
  try {
    // console.log('UpdateCompanyState :: try ', payload)
    yield put(updateCompanyStateSuccess({ ...other }))
  } catch (e) {
    // console.log('UpdateCompanyState :: e ', e)
  }
}

function* getCompaniesAll() {
  try {
    const response: ResponseCompany = yield methodGET({
      url: service.apiCompanies,
    })
    yield put(getCompaniesSuccess({ ...response }))
  } catch (e) {
    yield put(getCompaniesFail(e))
  }
}

function* getCompanyByOid({ payload }: any) {
  const { oid } = payload
  try {
    const response: ResponseCompany = yield methodGET({
      url: service.apiCompaniesByOid.replace(':oid', oid),
    })
    yield put(getCompanyByOidSuccess({ ...response }))
  } catch (e) {
    yield put(getCompanyByOidFail({ error: e }))
  }
}

function* getCompaniesByUser() {
  try {
    const response: ResponseCompany = yield methodGET({
      url: service.apiCompaniesByUser,
    })
    yield put(getCompaniesByUserSuccess({ ...response }))
  } catch (e) {
    yield put(getCompaniesByUserFail({ error: e }))
  }
}

function* companySaga() {
  yield all([
    takeLatest(ECompanyTypes.GET_COMPANY_BY_ROLE_REQUEST, getCompanyByRole),
    takeLatest(ECompanyTypes.POST_COMPANY_REQUEST, postCompany),
    takeLatest(ECompanyTypes.SELECT_COMPANY_REQUEST, SelectCompany),
    takeLatest(ECompanyTypes.UPDATE_COMPANY_REQUEST, UpdateCompany),
    takeLatest(ECompanyTypes.POST_MEDIA_COMPANY_LOGO_REQUEST, UploadLogo),
    takeLatest(
      ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_REQUEST,
      UploadLogoSide
    ),
    takeLatest(ECompanyTypes.UPDATE_COMPANY_STATE_REQUEST, UpdateCompanyState),
    takeLatest(ECompanyTypes.GET_COMPANY_REQUEST, getCompaniesAll),
    takeLatest(ECompanyTypes.GET_COMPANY_BY_OID_REQUEST, getCompanyByOid),
    takeLatest(ECompanyTypes.GET_COMPANIES_BY_USER_REQUEST, getCompaniesByUser),
  ])
}

export default companySaga
