import { all, put, takeLatest } from 'redux-saga/effects'
import {
  getRoleSuccess,
  getRoleFail,
  createRoleSuccess,
  createRoleFail,
  getRoleCompanyAllSuccess,
  getRoleCompanyAllFail,
  getRolesCompanyByOidOverviewSuccess,
  getRolesCompanyBtOidOverviewFail,
  postRolesCompaniesPermissionsSuccess,
  postRolesCompaniesPermissionsFail,
  postRolesCompaniesBranchSuccess,
  postRolesCompaniesBranchFail,
  updateRoleStateSuccess,
  getRoleByOidSuccess,
  getRoleByOidFail,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
} from 'store/actions'
import { ERoleTypes } from 'store/actionTypes'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'
import { IResponse } from 'store/types'
import { IRoleCompanyDetail, IRoleDetail } from 'store/types/role.interface'

const { methodGET, methodPOST } = requestManagement

function* getRole({ payload }: any) {
  // console.log('getRole :: ', payload)
  const { ...other } = payload
  try {
    const response: IResponse = yield methodGET({
      url: service.apiRole,
      param: {
        ...other,
      },
    })
    yield put(getRoleSuccess({ role: response.data }))
  } catch (e) {
    // console.log('getRole catch :: ', e)
    yield put(getRoleFail({ error: e }))
  }
}

function* createRole({ payload }: any) {
  // console.log('createRole :: ', payload)
  const { ...other } = payload
  try {
    const response: IResponse = yield methodPOST({
      url: service.apiRole,
      data: other,
    })
    // console.log('createRole :: response', response)
    if (response?.success && response?.status !== 500) {
      yield put(createRoleSuccess({ ...response }))
    } else {
      yield put(createRoleFail({ error: response }))
    }
  } catch (e) {
    yield put(createRoleFail({ error: e }))
  }
}

function* getRoleCompanyAll() {
  try {
    const response: IResponse = yield methodGET({
      url: service.apiRoleCompanyAll,
    })
    // console.log('getRoleCompanyAll :: ', response)
    yield put(getRoleCompanyAllSuccess({ ...response }))
  } catch (e) {
    yield put(getRoleCompanyAllFail({ error: e }))
  }
}

function* getRolesCompanyByOidOverview({ payload }: any) {
  const { oid, ...other } = payload
  // console.log('getRolesCompanyByOidOverview :: payload ', payload)
  try {
    const response: IResponse = yield methodGET({
      url: service.apiRoleCompanyByOidOverview.replace(':oid', oid),
      param: {
        ...other,
      },
    })
    // console.log('getRolesCompanyByOidOverview :: response ', response)
    yield put(getRolesCompanyByOidOverviewSuccess({ role: response }))
  } catch (e) {
    // console.log('getRolesCompanyByOidOverview :: e ', e)
    yield put(getRolesCompanyBtOidOverviewFail({ error: e }))
  }
}

function* postRolesCompaniesPermissions({ payload }: any) {
  const { ...other } = payload
  // console.log('postRolesCompaniesPermissions :: payload ', payload)

  try {
    const response: IResponse = yield methodPOST({
      url: service.apiRolesCompaniesPermissions,
      data: {
        ...other,
      },
    })
    yield put(postRolesCompaniesPermissionsSuccess({ ...response }))
  } catch (e) {
    yield put(postRolesCompaniesPermissionsFail({ error: e }))
  }
}

function* postRolesCompaniesBranch({ payload }: any) {
  const { ...other } = payload
  // console.log('postRolesCompaniesBranch :: payload', payload)
  try {
    const response: IResponse = yield methodPOST({
      url: service.apiRolesCompaniesBranchs,
      data: {
        ...other,
      },
    })
    // console.log('postRolesCompaniesBranch :: response', response)
    yield put(postRolesCompaniesBranchSuccess({ ...response }))
  } catch (e) {
    // console.log('postRolesCompaniesBranch :: e', e)
    yield put(postRolesCompaniesBranchFail({ error: e }))
  }
}

function* updateRoleState({ payload }: any) {
  const { ...other } = payload
  // console.log('updateRoleState :: other ', other)
  try {
    yield put(updateRoleStateSuccess({ ...other }))
  } catch (e) {
    // console.log('updateRoleState :: e ', e)
  }
}

function* getRoleByOid({ payload }: any) {
  const { oid } = payload
  // console.log('getRoleByOid :: ', payload)
  try {
    const response: IResponse = yield methodGET({
      url: service.apiRoleId.replace(':oid', oid),
    })
    // console.log('getRoleByOid response :: ', response)
    if (response.success) {
      yield put(getRoleByOidSuccess({ ...response }))
    } else {
      yield put(getRoleByOidFail({ error: response }))
    }
  } catch (e) {
    // console.log('getRoleByOid e :: ', e)
    yield put(getRoleByOidFail({ error: e }))
  }
}

function* updateRole({ payload }: any) {
  const { oid, ...other } = payload
  try {
    const response: IResponse = yield methodPOST({
      url: service.apiRoleUpdate.replace(':oid', oid),
      data: {
        ...other,
      },
    })
    // console.log('getRoleByOid response :: ', response)
    if (response.success && response.status !== 500) {
      yield put(updateRoleSuccess({ ...response }))
    } else {
      yield put(updateRoleFail({ error: response }))
    }
  } catch (e) {
    // console.log('getRoleByOid e :: ', e)
    yield put(updateRoleFail({ error: e }))
  }
}

function* deleteRole({ payload }: any) {
  const { oid, ...other } = payload
  try {
    const response: IResponse = yield methodPOST({
      url: service.apiRoleDelete.replace(':oid', oid),
    })
    // console.log('deleteRole response :: ', response)
    if (response.success) {
      yield put(deleteRoleSuccess({ ...response }))
    } else {
      yield put(deleteRoleFail({ error: response }))
    }
  } catch (e) {
    // console.log('deleteRole e :: ', e)
    yield put(deleteRoleFail({ error: e }))
  }
}

function* roleSage() {
  yield all([
    takeLatest(ERoleTypes.GET_ROLE_REQUEST, getRole),
    takeLatest(ERoleTypes.GET_ROLE_COMPANY_ALL_REQUEST, getRoleCompanyAll),
    takeLatest(ERoleTypes.CREATE_ROLE_REQUEST, createRole),
    takeLatest(
      ERoleTypes.GET_ROLE_COMPANY_BY_ID_REQUEST,
      getRolesCompanyByOidOverview
    ),
    takeLatest(
      ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_REQUEST,
      postRolesCompaniesPermissions
    ),
    takeLatest(
      ERoleTypes.POST_ROLES_COMPANIES_BRANCH_REQUEST,
      postRolesCompaniesBranch
    ),
    takeLatest(ERoleTypes.UPDATE_ROLES_STATE_REQUEST, updateRoleState),
    takeLatest(ERoleTypes.GET_ROLE_BY_OID_REQUEST, getRoleByOid),
    takeLatest(ERoleTypes.UPDATE_ROLE_REQUEST, updateRole),
    takeLatest(ERoleTypes.DELETE_ROLE_REQUEST, deleteRole),
  ])
}

export default roleSage
