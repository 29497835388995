import { EUserTypes } from 'store/actionTypes'
import { IAddUserSuccess, ICheckEmployeeID, UserRequest } from 'store/types'
export const fetchUserList = (payload: any): UserRequest => ({
  type: EUserTypes.FETCH_LIST_USER,
  payload,
})

export const fetchUserByCompany = (payload: any): UserRequest => ({
  type: EUserTypes.FETCH_LIST_USER_BY_COMPANY,
  payload,
})

export const addNewUser = (payload: any): IAddUserSuccess => ({
  type: EUserTypes.ADD_USER_REQUEST,
  payload,
})

export const editUsers = (payload: any): IAddUserSuccess => ({
  type: EUserTypes.UPDATE_USER_REQUEST,
  payload,
})

export const deleteUser = (payload: any) => ({
  type: EUserTypes.DELETE_USER_REQUEST,
  payload,
})

export const checkEmployee = (payload: any): ICheckEmployeeID => ({
  type: EUserTypes.CHECK_EMPLOYEE_ID,
  payload,
})

export const setErrorEmployee = (payload: any) => ({
  type: EUserTypes.SET_ERROR_EMPLOYEE_ID,
  payload,
})

export const fetchRoleByCompany = (payload: string | undefined) => ({
  type: EUserTypes.FETCH_ROLE_BY_COMPANY,
  payload,
})

export const listRoleByCompany = (payload: any) => ({
  type: EUserTypes.LIST_ROLE_BY_COMPANY,
  payload,
})

export const listAllRoleInUser = (payload: any) => ({
  type: EUserTypes.LIST_ALL_ROLE_IN_USER,
  payload,
})

export const fetchUserSuccess = (payload: any) => ({
  type: EUserTypes.FETCH_LIST_USER_SUCCESS,
  payload,
})

export const fetchUserById = (payload: string) => ({
  type: EUserTypes.FETCH_USER_BY_ID,
  payload,
})

export const fetchUserByIdSuccess = (payload: any) => ({
  type: EUserTypes.FETCH_USER_BY_ID_SUCCESS,
  payload,
})

export const deleteUserSuccess = (payload: boolean) => ({
  type: EUserTypes.DELETE_USER_SUCCESS,
  payload,
})

export const addUserSuccess = (payload: any) => ({
  type: EUserTypes.ADD_USER_SUCCESS,
  payload,
})

export const editUserFailed = (payload: any) => ({
  type: EUserTypes.EDIT_USER_FAILED,
  payload,
})
