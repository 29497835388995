import { EMenuPermissionTypes } from 'store/actionTypes'

export const fetchMenuPermission = () => ({
  type: EMenuPermissionTypes.FETCH_MENU_PERMISSION,
})

export const setMenuPermission = (payload: any): any => ({
  type: EMenuPermissionTypes.SET_TAB_MENU_PERMISSION,
  payload,
})

export const fetchMenuPermissionByCompany = (payload: any): any => ({
  type: EMenuPermissionTypes.FETCH_SITE_APPLICATION,
  payload,
})

export const updateMenuPermission = (payload: any): any => ({
  type: EMenuPermissionTypes.UPDATE_SITE_APPLICATION,
  payload,
})

export const setSiteApplicationByCompany = (payload: any): any => ({
  type: EMenuPermissionTypes.SET_DATA_SITE_APPLICATION,
  payload,
})

export const setSelectSiteApplication = (payload: any): any => ({
  type: EMenuPermissionTypes.SET_SELECT_SITE_APPLICATION,
  payload,
})

export const updateMenuPermissionSuccess = (payload: boolean): any => ({
  type: EMenuPermissionTypes.UPDATE_MENU_PERMISSION_SUCCESS,
  payload,
})

export const updateMenuPermissionFailed = (payload: boolean): any => ({
  type: EMenuPermissionTypes.UPDATE_MENU_PERMISSION_FAILED,
  payload,
})

export const updateChartSetting = (payload: any) => ({
  type: EMenuPermissionTypes.UPDATE_CHART_SETTING,
  payload,
})

export const fetchChartSetting = (payload: any) => ({
  type: EMenuPermissionTypes.FETCH_CHART_SETTING,
  payload,
})

export const setChartSetting = (payload: any) => ({
  type: EMenuPermissionTypes.SET_CHART_SETTING,
  payload,
})
