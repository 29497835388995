import { ECompanyTypes } from 'store/actionTypes'
import { ICompanyActions, ICompanyState, ICompanyDetail } from 'store/types'

const initialState: ICompanyState = {
  loading: false,
  error: null,
  currentPage: null,
  lastPage: null,
  total: null,
  dataLists: [],
  detail: null,
  isOutOfStock: false,
  message: '',
  success: false,
  createSuccess: false,
  oidNewCompany: null,
  companyAll: [],
  companiesAllLoading: false,
  companiesByUser: [],
  companiesByUserLoading: false,
}

export default (state = initialState, action: ICompanyActions) => {
  switch (action.type) {
    case ECompanyTypes.GET_COMPANY_BY_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        message: '',
      }
    case ECompanyTypes.GET_COMPANY_BY_ROLE_SUCCESS:
      return {
        ...state,
        ...action.payload.company,
        dataLists:
          action.payload.company.currentPage !== 1 && !action?.payload?.isRole
            ? [...state.dataLists, ...action.payload.company.dataLists]
            : action?.payload?.company?.dataLists,
        loading: false,
        isOutOfStock:
          // action.payload.company.dataLists.length % 20 !== 0 ||
          // action?.payload?.company?.dataLists?.length === state.total,
          action.payload.company.currentPage ===
          action.payload.company.lastPage,
        message: action.payload.company.message,
        success: action.payload.company.success,
        error: null,
      }
    case ECompanyTypes.GET_COMPANY_BY_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        dataLists: [],
        error: action.payload.error,
        message: action.payload.error.message,
        success: action.payload.error.success,
      }
    case ECompanyTypes.SELECT_COMPANY_REQUEST:
      return { ...state, error: null, success: false, message: '' }
    case ECompanyTypes.SELECT_COMPANY_SUCCESS:
      return {
        ...state,
        detail: state.dataLists
          ? state.dataLists.find((data: ICompanyDetail) => {
              return data.oid === action.payload.oid
            })
          : null,
      }
    // * Update Company
    case ECompanyTypes.UPDATE_COMPANY_REQUEST:
      return { ...state, error: null, success: false, message: '' }
    case ECompanyTypes.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        detail: action.payload.response.data,
        message: action.payload.response.message,
        success: action.payload.response.success,
      }
    case ECompanyTypes.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        error: !action.payload.error.success,
        message: action.payload.error.message,
        success: action.payload.error.success,
      }
    // * Create Company
    case ECompanyTypes.POST_COMPANY_REQUEST:
      return { ...state, error: null, success: false, message: '' }
    case ECompanyTypes.POST_COMPANY_SUCCESS:
      return {
        ...state,
        // detail: action.payload.response.data,
        message: action.payload.response.message,
        createSuccess: action.payload.response.success,
        oidNewCompany: action.payload.response.data.oid,
      }
    case ECompanyTypes.POST_COMPANY_FAIL:
      return {
        ...state,
        error: !action.payload.error.success,
        createSuccess: action.payload.error.success,
        message: action.payload.error.message,
        oidNewCompany: null,
      }
    // * Logo
    case ECompanyTypes.POST_MEDIA_COMPANY_LOGO_REQUEST:
      return { ...state, error: null, success: false, message: '' }
    case ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        dataLists: state.dataLists.map((data: any) => {
          if (data?.oid === action?.payload?.data?.oid) {
            data.logo = action?.payload?.data?.logo
          }
          return data
        }),
        detail: null,
      }
    case ECompanyTypes.POST_MEDIA_COMPANY_LOGO_FAIL:
      return { ...state }
    // * Logo side
    case ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_REQUEST:
      return { ...state, error: null, success: false, message: '' }
    case ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_SUCCESS:
      return {
        ...state,
        dataLists: state.dataLists.map((data: any) => {
          if (data?.oid === action?.payload?.data?.oid) {
            data.logo_side = action?.payload?.data?.logo_side
          }
          return data
        }),
        detail: null,
      }
    case ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_FAIL:
      return { ...state, error: action.payload }
    // * Company State
    case ECompanyTypes.UPDATE_COMPANY_STATE_REQUEST:
      return { ...state }
    case ECompanyTypes.UPDATE_COMPANY_STATE_SUCCESS:
      return { ...state, ...action.payload }
    case ECompanyTypes.CLEAR_COMPANY_SELECT:
      return { ...state, detail: action.payload }
    // * Company All
    case ECompanyTypes.GET_COMPANY_REQUEST:
      return { ...state, companiesAllLoading: true }
    case ECompanyTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyAll: action.payload.data,
        companiesAllLoading: false,
      }
    case ECompanyTypes.GET_COMPANY_FAIL:
      return {
        ...state,
        companiesAllLoading: false,
        error: !action.payload.payload.error.success,
        message: action.payload.payload.error.message,
      }
    case ECompanyTypes.GET_COMPANY_BY_OID_REQUEST:
      return { ...state }
    case ECompanyTypes.GET_COMPANY_BY_OID_SUCCESS:
      return { ...state, detail: action.payload.data }
    case ECompanyTypes.GET_COMPANY_BY_OID_FAIL:
      return { ...state }
    case ECompanyTypes.GET_COMPANIES_BY_USER_REQUEST:
      return { ...state, companiesByUserLoading: true }
    case ECompanyTypes.GET_COMPANIES_BY_USER_SUCCESS:
      return {
        ...state,
        companiesByUser: action.payload.data,
        companiesByUserLoading: false,
      }
    case ECompanyTypes.GET_COMPANIES_BY_USER_FAIL:
      return { ...state }
    default:
      return { ...state }
  }
}
