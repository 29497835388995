import { EMenuTypes } from 'store/actionTypes'
import {
  IGetMenuRequest,
  IGetMenuSuccess,
  IMenuState,
  IPayload,
  IFailPayload,
  IGetMenuFail,
} from 'store/types'

export const getMenuRequest = (payload: IPayload): IGetMenuRequest => ({
  type: EMenuTypes.GET_MENU_REQUEST,
  payload,
})
export const getMenuSuccess = (payload: IMenuState): IGetMenuSuccess => ({
  type: EMenuTypes.GET_MENU_SUCCESS,
  payload,
})
export const getMenuFail = (payload: IFailPayload): IGetMenuFail => ({
  type: EMenuTypes.GET_MENU_FAIL,
  payload,
})
