import { EMasterDataTypes } from 'store/actionTypes'
import {
  IPayload,
  //Country
  ICountryRequest,
  ICountrySuccess,
  ICountryFail,
  ICountrySuccessPayload,
  //Province
  IProvinceRequest,
  IProvinceSuccess,
  IProvinceFail,
  IProvinceSuccessPayload,
  //District
  IDistrictRequest,
  IDistrictSuccess,
  IDistrictFail,
  IDistrictSuccessPayload,
  //Sub-District
  ISubDistrictRequest,
  ISubDistrictSuccess,
  ISubDistrictFail,
  ISubDistrictSuccessPayload,
  //Fail-Payload
  IFailPayload,
  IZipCodeRequest,
  IZipCodeSuccess,
  IZipCodeFail,
} from 'store/types'

//Country
export const fetchCountryRequest = (payload: IPayload): ICountryRequest => ({
  type: EMasterDataTypes.GET_COUNTRY_REQUEST,
  payload,
})
export const fetchCountrySuccess = (
  payload: ICountrySuccessPayload
): ICountrySuccess => ({
  type: EMasterDataTypes.GET_COUNTRY_SUCCESS,
  payload,
})
export const fetchCountryFail = (payload: IFailPayload): ICountryFail => ({
  type: EMasterDataTypes.GET_COUNTRY_FAIL,
  payload,
})
//Province
export const fetchProvinceRequest = (payload: IPayload): IProvinceRequest => ({
  type: EMasterDataTypes.GET_PROVINCE_REQUEST,
  payload,
})
export const fetchProvinceSuccess = (
  payload: IProvinceSuccessPayload
): IProvinceSuccess => ({
  type: EMasterDataTypes.GET_PROVINCE_SUCCESS,
  payload,
})
export const fetchProvinceFail = (payload: IFailPayload): IProvinceFail => ({
  type: EMasterDataTypes.GET_PROVINCE_FAIL,
  payload,
})
//District
export const fetchDistrictRequest = (payload: IPayload): IDistrictRequest => ({
  type: EMasterDataTypes.GET_DISTRICT_REQUEST,
  payload,
})
export const fetchDistrictSuccess = (
  payload: IDistrictSuccessPayload
): IDistrictSuccess => ({
  type: EMasterDataTypes.GET_DISTRICT_SUCCESS,
  payload,
})
export const fetchDistrictFail = (payload: IFailPayload): IDistrictFail => ({
  type: EMasterDataTypes.GET_DISTRICT_FAIL,
  payload,
})
//Sub-District
export const fetchSubDistrictRequest = (
  payload: IPayload
): ISubDistrictRequest => ({
  type: EMasterDataTypes.GET_SUB_DISTRICT_REQUEST,
  payload,
})
export const fetchSubDistrictSuccess = (
  payload: ISubDistrictSuccessPayload
): ISubDistrictSuccess => ({
  type: EMasterDataTypes.GET_SUB_DISTRICT_SUCCESS,
  payload,
})
export const fetchSubDistrictFail = (
  payload: IFailPayload
): ISubDistrictFail => ({
  type: EMasterDataTypes.GET_SUB_DISTRICT_FAIL,
  payload,
})
//ZipCode
export const fetchZipCodeRequest = (payload: IPayload): IZipCodeRequest => ({
  type: EMasterDataTypes.GET_ZIP_CODE_REQUEST,
  payload,
})
export const fetchZipCodeSuccess = (payload: any): IZipCodeSuccess => ({
  type: EMasterDataTypes.GET_ZIP_CODE_SUCCESS,
  payload,
})
export const fetchZipCodeFail = (payload: IFailPayload): IZipCodeFail => ({
  type: EMasterDataTypes.GET_ZIP_CODE_FAIL,
  payload,
})
