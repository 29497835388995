import { EMenuTypes } from 'store/actionTypes'
import { IMenuActions, IMenuState } from 'store/types'

const initialState: IMenuState = {
  loading: false,
  error: false,
  data: [],
  message: '',
  success: false,
}

export default (state = initialState, action: IMenuActions) => {
  switch (action.type) {
    case EMenuTypes.GET_MENU_REQUEST:
      return { ...state }
    case EMenuTypes.GET_MENU_SUCCESS:
      return { ...state }
    case EMenuTypes.GET_MENU_FAIL:
      return { ...state }
    default:
      return { ...state }
  }
}
