import { EAuthenticationTypes } from 'store/actionTypes'

import {
  LoginRequest,
  ILoginState,
  RegisterRequest,
  IRegisterState,
  IForgetPasswordState,
  CheckEmailRequest,
  PinStatus,
} from 'store/types'

export const registerRequest = (payload: IRegisterState): RegisterRequest => ({
  type: EAuthenticationTypes.POST_REGISTER,
  payload,
})

export const loginRequest = (payload: ILoginState): LoginRequest => ({
  type: EAuthenticationTypes.POST_LOGIN,
  payload,
})

export const checkEmailRequest = (
  payload: IForgetPasswordState
): CheckEmailRequest => ({
  type: EAuthenticationTypes.CHECK_EMAIL,
  payload,
})

export const checkPinRequest = (payload: string | number): PinStatus => ({
  type: EAuthenticationTypes.CHECK_PIN,
  payload,
})

export const resetPasswordRequest = (payload: IForgetPasswordState): any => ({
  type: EAuthenticationTypes.POST_NEW_PASSWORD,
  payload,
})

export const setTabForgetPassword = (payload: number): any => ({
  type: EAuthenticationTypes.SET_TAB_NEW_PASSWORD,
  payload,
})

export const setNewRefreshToken = (payload: any): any => ({
  type: EAuthenticationTypes.SET_NEW_REFRESH_TOKEN,
  payload,
})
export const setStatusMessage = (payload: any): any => ({
  type: EAuthenticationTypes.STATUS_CHECK_EMAIL,
  payload,
})

export const loginSuccess = (payload: any): any => ({
  type: EAuthenticationTypes.LOGIN_SUCCESS,
  payload,
})

export const loginFailed = (payload: any): any => ({
  type: EAuthenticationTypes.LOGIN_FAILED,
  payload,
})
export const registerSuccess = (payload: any): any => ({
  type: EAuthenticationTypes.REGISTER_SUCCESS,
  payload,
})

export const pinPasswordStatus = (payload: any): any => ({
  type: EAuthenticationTypes.PIN_PASSWORD_STATUS,
  payload,
})

export const resetPasswordFailed = (payload: any): any => ({
  type: EAuthenticationTypes.RESET_PASSWORD_FAILED,
  payload,
})

export const refreshToken = () => ({
  type: EAuthenticationTypes.REFRESH_TOKEN_AUTHENTICATION,
})

export const refreshTokenFail = (payload: any): any => ({
  type: EAuthenticationTypes.REFRESH_TOKEN_AUTHENTICATION_FAIL,
  payload,
})
