import { EBranchTypes } from 'store/actionTypes'
import {
  IPayload,
  //Get
  IGetBranchRequest,
  IGetBranchSuccess,
  IGetBranchFail,
  IGetBranchSuccessPayload,
  IGetBranchFailPayload,
  //Post
  IPostBranchRequest,
  IPostBranchSuccess,
  IPostBranchFail,
  //Update
  IUpdateBranchRequest,
  IUpdateBranchSuccess,
  IUpdateBranchFail,
  IBranchDetail,
  //Delete
  IDeletePayload,
  IDeleteBranchRequest,
  IDeleteBranchSuccess,
  IDeleteBranchFail,
  IBranchState,
  IUpdateBranchStateRequest,
  IUpdateBranchStateSuccess,
  IGetBranchByOidRequest,
  IGetBranchByOidSuccess,
  IFailPayload,
  IGetBranchByOidResponse,
  IGetBranchAllRequest,
  IGetBranchAllFail,
  IGetBranchAllSuccess,
} from 'store/types'

//Get
export const fetchBranchRequest = (payload: IPayload): IGetBranchRequest => ({
  type: EBranchTypes.GET_BRANCH_REQUEST,
  payload,
})
export const fetchBranchSuccess = (
  payload: IBranchState
): IGetBranchSuccess => ({
  type: EBranchTypes.GET_BRANCH_SUCCESS,
  payload,
})
export const fetchBranchFail = (
  payload: IGetBranchFailPayload
): IGetBranchFail => ({
  type: EBranchTypes.GET_BRANCH_FAIL,
  payload,
})
//Post
export const createBranchRequest = (
  payload: IBranchState
): IPostBranchRequest => ({
  type: EBranchTypes.POST_BRANCH_REQUEST,
  payload,
})
export const createBranchSuccess = (
  payload: IGetBranchSuccessPayload
): IPostBranchSuccess => ({
  type: EBranchTypes.POST_BRANCH_SUCCESS,
  payload,
})
export const createBranchFail = (
  payload: IGetBranchFailPayload
): IPostBranchFail => ({
  type: EBranchTypes.POST_BRANCH_FAIL,
  payload,
})
//Update
export const updateBranchRequest = (
  payload: IBranchDetail
): IUpdateBranchRequest => ({
  type: EBranchTypes.UPDATE_BRANCH_REQUEST,
  payload,
})
export const updateBranchSuccess = (
  payload: IGetBranchSuccessPayload
): IUpdateBranchSuccess => ({
  type: EBranchTypes.UPDATE_BRANCH_SUCCESS,
  payload,
})
export const updateBranchFail = (
  payload: IGetBranchFailPayload
): IUpdateBranchFail => ({
  type: EBranchTypes.UPDATE_BRANCH_FAIL,
  payload,
})
//Delete
export const deleteBranchRequest = (
  payload: IDeletePayload
): IDeleteBranchRequest => ({
  type: EBranchTypes.DELETE_BRANCH_REQUEST,
  payload,
})
export const deleteBranchSuccess = (
  payload: IGetBranchSuccessPayload
): IDeleteBranchSuccess => ({
  type: EBranchTypes.DELETE_BRANCH_SUCCESS,
  payload,
})
export const deleteBranchFail = (
  payload: IGetBranchFailPayload
): IDeleteBranchFail => ({
  type: EBranchTypes.DELETE_BRANCH_FAIL,
  payload,
})
//Update State
export const updateBranchStateRequest = (
  payload: IBranchState
): IUpdateBranchStateRequest => ({
  type: EBranchTypes.UPDATE_BRANCH_STATE_REQUEST,
  payload,
})
export const updateBranchStateSuccess = (
  payload: IBranchState
): IUpdateBranchStateSuccess => ({
  type: EBranchTypes.UPDATE_BRANCH_STATE_SUCCESS,
  payload,
})
//Get Branch by oid
export const getBranchByOidRequest = (
  payload: IPayload
): IGetBranchByOidRequest => ({
  type: EBranchTypes.GET_BRANCH_BY_ID_REQUEST,
  payload,
})
export const getBranchByOidSuccess = (
  payload: IGetBranchByOidResponse
): IGetBranchByOidSuccess => ({
  type: EBranchTypes.GET_BRANCH_BY_ID_SUCCESS,
  payload,
})
export const getBranchByOidFail = (payload: IFailPayload) => ({
  type: EBranchTypes.GET_BRANCH_BY_ID_FAIL,
  payload,
})
//Get all branch
export const getBranchAllRequest = (payload: any): IGetBranchAllRequest => ({
  type: EBranchTypes.GET_BRANCH_ALL_REQUEST,
  payload,
})
export const getBranchAllSuccess = (payload: any): IGetBranchAllSuccess => ({
  type: EBranchTypes.GET_BRANCH_ALL_SUCCESS,
  payload,
})
export const getBranchAllFail = (payload: any): IGetBranchAllFail => ({
  type: EBranchTypes.GET_BRANCH_ALL_FAIL,
  payload,
})
