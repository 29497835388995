const BACKEND_ENDPOINT = process.env.BACKEND_ENDPOINT
const BASE_URL = process.env.BASE_URL
const API_VERSION = process.env.API_VERSION

export default {
  //authentication
  register: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/register`,
  login: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/log-in`,
  checkMail: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/check-email`,
  checkPin: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/check-secure`,
  resetPassword: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/forgot-password`,
  refreshToken: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/refresh-token`,
  announcement: `${BACKEND_ENDPOINT}/${API_VERSION}/authentication/announcement`,

  //Company
  apiCompanies: `${BACKEND_ENDPOINT}/${API_VERSION}/companies`,
  apiCompaniesByOid: `${BACKEND_ENDPOINT}/${API_VERSION}/companies/:oid`,
  apiCompaniesByRole: `${BACKEND_ENDPOINT}/${API_VERSION}/companies/role/:oid`,
  apiCompaniesUpdate: `${BACKEND_ENDPOINT}/${API_VERSION}/companies/:oid/update`,
  apiCompaniesByUser: `${BACKEND_ENDPOINT}/${API_VERSION}/companies/by-user`,
  //Branch
  apiBranchs: `${BACKEND_ENDPOINT}/${API_VERSION}/branchs`,
  apiBranchsId: `${BACKEND_ENDPOINT}/${API_VERSION}/branchs/:oid`,
  apiBranchUpdate: `${BACKEND_ENDPOINT}/${API_VERSION}/branchs/:oid/update`,
  apiBranchDelete: `${BACKEND_ENDPOINT}/${API_VERSION}/branchs/:oid/delete`,
  apiBranchAll: `${BACKEND_ENDPOINT}/${API_VERSION}/branchs/all`,

  //site application
  apiMenuPermission: `${BACKEND_ENDPOINT}/${API_VERSION}/menu`,
  apiGetSiteAppByCompany: `${BACKEND_ENDPOINT}/${API_VERSION}/companies-permissions/:oid`,
  apiSaveSiteApp: `${BACKEND_ENDPOINT}/${API_VERSION}/companies-permissions`,
  apiChartSetting: `${BACKEND_ENDPOINT}/${API_VERSION}/settings`,
  apiChartSettingByCompany: `${BACKEND_ENDPOINT}/${API_VERSION}/settings/:oid`,

  //user
  apiUsers: `${BACKEND_ENDPOINT}/${API_VERSION}/users`,
  apiEmployeeID: `${BACKEND_ENDPOINT}/${API_VERSION}/global/employee`,
  apiAllRole: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/company/all`,
  apiRoleByCompany: `${BACKEND_ENDPOINT}/${API_VERSION}/users/roles/:oid`,
  apiUserDelete: `${BACKEND_ENDPOINT}/${API_VERSION}/users/:oid/delete`,
  apiGetUsers: `${BACKEND_ENDPOINT}/${API_VERSION}/users/:oid`,
  apiUserByCompany: `${BACKEND_ENDPOINT}/${API_VERSION}/users/company/:oid`,
  apiUserUpdate: `${BACKEND_ENDPOINT}/${API_VERSION}/users/:oid/update`,

  //Master Data
  apiMasterDataCountry: `${BACKEND_ENDPOINT}/${API_VERSION}/global/country`,
  apiMasterDataProvince: `${BACKEND_ENDPOINT}/${API_VERSION}/global/province/:country_id`,
  apiMasterDataDistrict: `${BACKEND_ENDPOINT}/${API_VERSION}/global/district/:province_id`,
  apiMasterDataSubDistrict: `${BACKEND_ENDPOINT}/${API_VERSION}/global/subdistrict/:district_id`,
  apiMasterDataZipCode: `${BACKEND_ENDPOINT}/${API_VERSION}/global/zipcode/:zipcode`,
  //Role Data
  apiRole: `${BACKEND_ENDPOINT}/${API_VERSION}/roles`,
  apiRoleAll: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/all`,
  apiRoleCompanyByOidOverview: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/company/:oid/overview`,
  apiRoleId: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/:oid`,
  apiRoleUpdate: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/:oid/update`,
  apiRoleDelete: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/:oid/delete`,
  apiRolesCompaniesPermissions: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/companies/permissions`,
  apiRolesCompaniesBranchs: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/companies/branchs`,
  apiRoleCompanyAll: `${BACKEND_ENDPOINT}/${API_VERSION}/roles/company/all`,
  //Media
  apiMediaCompanyLogo: `${BACKEND_ENDPOINT}/${API_VERSION}/media/company/:oid/logo`,
  apiMediaCompanyLogoSide: `${BACKEND_ENDPOINT}/${API_VERSION}/media/company/:oid/logo_side`,
  //Menus
  apiMenu: `${BACKEND_ENDPOINT}/${API_VERSION}/menu`,
  //Branch Group
  apiBranchGroup: `${BACKEND_ENDPOINT}/${API_VERSION}/branch-groups`,
  apiEditBranchGroup: `${BACKEND_ENDPOINT}/${API_VERSION}/branch-groups/:oid/edit`,
  apiDeleteBranchGroup: `${BACKEND_ENDPOINT}/${API_VERSION}/branch-groups/:oid/delete`,
}
