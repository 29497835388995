import { ERoleTypes } from 'store/actionTypes'
import {
  IRoleActions,
  IRoleDetail,
  IRoleState,
} from 'store/types/role.interface'

const initialState: IRoleState = {
  loading: false,
  currentPage: 1,
  total: 0,
  perPage: 1,
  lastPage: 1,
  dataLists: [],
  rolesCompanyOverview: [],
  companyAll: [],
  companyPermissions: [],
  companyBranchs: [],
  roleDetail: null,
}

export default (state = initialState, action: IRoleActions) => {
  switch (action.type) {
    //Get Role
    case ERoleTypes.GET_ROLE_REQUEST:
      return { ...state, loading: true, error: null }
    case ERoleTypes.GET_ROLE_SUCCESS:
      return { ...state, loading: false, ...action.payload.role }
    case ERoleTypes.GET_ROLE_FAIL:
      return { ...state, loading: false, error: action.payload.error }
    //Get Role Company All
    case ERoleTypes.GET_ROLE_COMPANY_ALL_REQUEST:
      return { ...state }
    case ERoleTypes.GET_ROLE_COMPANY_ALL_SUCCESS:
      return { ...state, companyAll: action.payload.data }
    case ERoleTypes.GET_ROLE_COMPANY_ALL_FAIL:
      return { ...state, companyAll: [], error: action.payload.error }
    //Get Role All
    case ERoleTypes.GET_ROLE_ALL_REQUEST:
      return { ...state }
    case ERoleTypes.GET_ROLE_ALL_SUCCESS:
      return { ...state, rolesAll: action.payload.data }
    case ERoleTypes.GET_ROLE_ALL_FAIL:
      return { ...state, rolesAll: [], error: action.payload.error }
    //Create
    case ERoleTypes.CREATE_ROLE_REQUEST:
      return { ...state }
    case ERoleTypes.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
      }
    case ERoleTypes.CREATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        message: `${action.payload.error.message}Status code ${action.payload.error.statusCode}`,
      }
    //Get Roles Company oid overview
    case ERoleTypes.GET_ROLE_COMPANY_BY_ID_REQUEST:
      return { ...state, success: false, message: '', loading: true }
    case ERoleTypes.GET_ROLE_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        message: action.payload.role.message,
        ...action.payload.role.data,
        dataLists: action?.payload?.role?.data?.dataLists || [],
        loading: false,
      }
    case ERoleTypes.GET_ROLE_COMPANY_BY_ID_FAIL:
      return { ...state, loading: false }
    //Post Roles Companies Permissions
    case ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_REQUEST:
      return { ...state }
    case ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_SUCCESS:
      return { ...state, companyPermissions: action.payload.data }
    case ERoleTypes.POST_ROLES_COMPANIES_PERMISSIONS_FAIL:
      return {
        ...state,
        companyPermissions: [],
        error: action.payload.error,
        message: action.payload.error.message,
      }
    //Post Roles Companies Branchs
    case ERoleTypes.POST_ROLES_COMPANIES_BRANCH_REQUEST:
      return { ...state }
    case ERoleTypes.POST_ROLES_COMPANIES_BRANCH_SUCCESS:
      return { ...state, companyBranchs: action.payload.data }
    case ERoleTypes.POST_ROLES_COMPANIES_BRANCH_FAIL:
      return {
        ...state,
        companyBranchs: [],
        message: action.payload.error.message,
        error: !action.payload.error.success,
      }
    case ERoleTypes.UPDATE_ROLES_STATE_REQUEST:
      return {
        ...state,
      }
    case ERoleTypes.UPDATE_ROLES_STATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    //Get Role by oid
    case ERoleTypes.GET_ROLE_BY_OID_REQUEST:
      return { ...state }
    case ERoleTypes.GET_ROLE_BY_OID_SUCCESS:
      return { ...state, roleDetail: action.payload.data }
    case ERoleTypes.GET_ROLE_BY_OID_FAIL:
      return {
        ...state,
        error: !action.payload.error.success,
        message: action.payload.error.message,
      }
    //Update Role
    case ERoleTypes.UPDATE_ROLE_REQUEST:
      return { ...state }
    case ERoleTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      }
    case ERoleTypes.UPDATE_ROLE_FAIL:
      return {
        ...state,
        success: false,
        error: action.payload.error,
        message: action.payload.error.message,
      }
    //Delete Role
    case ERoleTypes.DELETE_ROLE_REQUEST:
      return { ...state, success: false, message: '' }
    case ERoleTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        dataLists: state.dataLists?.filter(
          (data: IRoleDetail) => data.oid !== action.payload.data.oid
        ),
        total: state?.total ? state?.total - 1 : state?.total,
        success: action.payload.success,
        message: action.payload.message,
      }
    case ERoleTypes.DELETE_ROLE_FAIL:
      return {
        ...state,
        success: false,
        error: action.payload.error,
        message: action.payload.error.message,
      }
    default:
      return { ...state }
  }
}
