import { combineReducers } from 'redux'
import companyReducer from './company'
import authenticationReducer from './authentication'
import siteappReducer from './site-app'
import userReducer from './user'
import branchReducer from './branch'
import masterDataReducer from './masterData'
import roleReducer from './role'
import menuReducer from './menu'
import branchGroupReducer from './branchGroup'

const rootReducer = combineReducers({
  company: companyReducer,
  authentication: authenticationReducer,
  branch: branchReducer,
  masterData: masterDataReducer,
  role: roleReducer,
  siteapplication: siteappReducer,
  user: userReducer,
  menu: menuReducer,
  branchGroup: branchGroupReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
