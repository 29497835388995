import { EBranchGroupTypes } from 'store/actionTypes'
import { IBranchGroupState, IBranchGroupActions } from 'store/types'

const initialState: IBranchGroupState = {
  loading: false,
  error: false,
  data: {
    dataLists: [],
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  },
  dataEdit: null,
  success: false,
  message: '',
}

export default (state = initialState, action: IBranchGroupActions) => {
  // console.log(`BranchGroup reducer :: ${action.type}`, { state, action })
  switch (action.type) {
    case EBranchGroupTypes.GET_BRANCH_GROUP_REQUEST:
      return { ...state, loading: true }
    case EBranchGroupTypes.GET_BRANCH_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case EBranchGroupTypes.GET_BRANCH_GROUP_FAIL:
      return { ...state, loading: false }
    case EBranchGroupTypes.POST_BRANCH_GROUP_REQUEST:
      return { ...state }
    case EBranchGroupTypes.POST_BRANCH_GROUP_SUCCESS:
      return {
        ...state,
        success: true,
        message: action.payload.message,
      }
    case EBranchGroupTypes.POST_BRANCH_GROUP_FAIL:
      return { ...state, error: true, message: action.payload.message }
    case EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_REQUEST:
      return { ...state, success: false }
    case EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_SUCCESS:
      return {
        ...state,
        dataEdit: action.payload.data,
      }
    case EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_FAIL:
      return {
        ...state,
      }
    case EBranchGroupTypes.DELETE_BRANCH_GROUP_REQUEST:
      return { ...state }
    case EBranchGroupTypes.DELETE_BRANCH_GROUP_SUCCESS:
      return {
        ...state,
        success: true,
        message: action.payload.message,
      }
    case EBranchGroupTypes.DELETE_BRANCH_GROUP_FAIL:
      return { ...state, error: true, message: action.payload.message }
    case EBranchGroupTypes.UPDATE_BRANCH_GROUP_STATE_REQUEST:
      return { ...state }
    case EBranchGroupTypes.UPDATE_BRANCH_GROUP_STATE_SUCCESS:
      return { ...state, ...action.payload }
    default: {
      return { ...state }
    }
  }
}
