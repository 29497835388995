import { all, put, takeLatest } from 'redux-saga/effects'
import { getMenuSuccess, getMenuFail } from 'store/actions'
import { IMenuState } from 'store/types'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'
import { EMenuTypes } from 'store/actionTypes'

const { methodGET } = requestManagement

interface Response {
  data: any
  message: string
  success: boolean
}

function* getMenu({ payload }: any) {
  //   const { ...other } = payload
  //   console.log('getMenu :: ', payload)
  try {
    const response: IMenuState = yield methodGET({
      url: service.apiMenu,
    })
    yield put(getMenuSuccess({ ...response }))
  } catch (e) {
    yield put(getMenuFail({ error: e }))
  }
}

function* menuSaga() {
  yield all([takeLatest(EMenuTypes.GET_MENU_REQUEST, getMenu)])
}

export default menuSaga
