import { ECompanyTypes } from 'store/actionTypes'

import {
  IPayload,
  ICompanyDetail,
  ICompanyRequest,
  ICompanySuccess,
  ICompanyFailure,
  ICompanySuccessPayload,
  ICompanyFailurePayload,
  ISelectCompanyRequest,
  ISelectCompanySuccess,
  IUpdateCompanyRequest,
  IUpdateCompanySuccess,
  IUpdateCompanyPayload,
  IPostCompanyRequest,
  IPostCompanySuccess,
  IPostCompanyFail,
  IMediaCompanyLogoRequest,
  IMediaCompanyLogoSuccess,
  IMediaCompanyLogoFail,
  IMediaCompanyLogoSideRequest,
  IMediaCompanyLogoSideSuccess,
  IMediaCompanyLogoSideFail,
  ICompanyState,
  ICompanyStateRequest,
  ICompanyStateSuccess,
  IGetCompaniesRequest,
  IGetCompaniesSuccess,
  IGetCompaniesFail,
  ICompanyAllResponse,
  IGetCompanyByOidRequest,
  IGetCompanyByOidSuccess,
  IGetCompanyByOidFail,
  IUpdateCompanyFail,
  IGetCompaniesByUserRequest,
  IGetCompaniesByUserSuccess,
  IGetCompaniesByUserFail,
} from 'store/types'

export const fetchCompanyRequest = (payload: IPayload): ICompanyRequest => ({
  type: ECompanyTypes.GET_COMPANY_BY_ROLE_REQUEST,
  payload,
})

export const fetchCompanySuccess = (
  payload: ICompanySuccessPayload
): ICompanySuccess => ({
  type: ECompanyTypes.GET_COMPANY_BY_ROLE_SUCCESS,
  payload,
})

export const fetchCompanyFail = (
  payload: ICompanyFailurePayload
): ICompanyFailure => ({
  type: ECompanyTypes.GET_COMPANY_BY_ROLE_FAIL,
  payload,
})

export const selectCompanyRequest = (
  payload: IPayload
): ISelectCompanyRequest => ({
  type: ECompanyTypes.SELECT_COMPANY_REQUEST,
  payload,
})

export const selectCompanySuccess = (
  payload: IPayload
): ISelectCompanySuccess => ({
  type: ECompanyTypes.SELECT_COMPANY_SUCCESS,
  payload,
})

export const updateCompanyRequest = (
  payload: ICompanyDetail
): IUpdateCompanyRequest => ({
  type: ECompanyTypes.UPDATE_COMPANY_REQUEST,
  payload,
})

export const updateCompanySuccess = (
  payload: IUpdateCompanyPayload
): IUpdateCompanySuccess => ({
  type: ECompanyTypes.UPDATE_COMPANY_SUCCESS,
  payload,
})

export const updateCompanyFail = (
  payload: ICompanyFailurePayload
): IUpdateCompanyFail => ({
  type: ECompanyTypes.UPDATE_COMPANY_FAILURE,
  payload,
})

//Create Company
export const createCompanyRequest = (
  payload: ICompanyDetail
): IPostCompanyRequest => ({
  type: ECompanyTypes.POST_COMPANY_REQUEST,
  payload,
})
export const createCompanySuccess = (
  payload: IUpdateCompanyPayload
): IPostCompanySuccess => ({
  type: ECompanyTypes.POST_COMPANY_SUCCESS,
  payload,
})
export const createCompanyFail = (
  payload: ICompanyFailurePayload
): IPostCompanyFail => ({
  type: ECompanyTypes.POST_COMPANY_FAIL,
  payload,
})
//Media Logo
export const uploadMediaCompanyLogoRequest = (
  payload: IPayload
): IMediaCompanyLogoRequest => ({
  type: ECompanyTypes.POST_MEDIA_COMPANY_LOGO_REQUEST,
  payload,
})
export const uploadMediaCompanyLogoSuccess = (
  payload: ICompanyState
): IMediaCompanyLogoSuccess => ({
  type: ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SUCCESS,
  payload,
})
export const updateMediaCompanyLogoFail = (
  payload: ICompanyFailure
): IMediaCompanyLogoFail => ({
  type: ECompanyTypes.POST_MEDIA_COMPANY_LOGO_FAIL,
  payload,
})
//Media Logo side
export const uploadMediaCompanyLogoSideRequest = (
  payload: IPayload
): IMediaCompanyLogoSideRequest => ({
  type: ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_REQUEST,
  payload,
})
export const uploadMediaCompanyLogoSideSuccess = (
  payload: ICompanyState
): IMediaCompanyLogoSideSuccess => ({
  type: ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_SUCCESS,
  payload,
})
export const updateMediaCompanyLogoSideFail = (
  payload: ICompanyFailure
): IMediaCompanyLogoSideFail => ({
  type: ECompanyTypes.POST_MEDIA_COMPANY_LOGO_SIDE_FAIL,
  payload,
})
//State
export const updateCompanyStateRequest = (
  payload: ICompanyState
): ICompanyStateRequest => ({
  type: ECompanyTypes.UPDATE_COMPANY_STATE_REQUEST,
  payload,
})
export const updateCompanyStateSuccess = (
  payload: ICompanyState
): ICompanyStateSuccess => ({
  type: ECompanyTypes.UPDATE_COMPANY_STATE_SUCCESS,
  payload,
})
//Get Company All
export const getCompaniesRequest = (): IGetCompaniesRequest => ({
  type: ECompanyTypes.GET_COMPANY_REQUEST,
})
export const getCompaniesSuccess = (
  payload: ICompanyAllResponse
): IGetCompaniesSuccess => ({
  type: ECompanyTypes.GET_COMPANY_SUCCESS,
  payload,
})
export const getCompaniesFail = (payload: any): IGetCompaniesFail => ({
  type: ECompanyTypes.GET_COMPANY_FAIL,
  payload,
})
//Get Company by oid
export const getCompanyByOidRequest = (
  payload: IPayload
): IGetCompanyByOidRequest => ({
  type: ECompanyTypes.GET_COMPANY_BY_OID_REQUEST,
  payload,
})
export const getCompanyByOidSuccess = (
  payload: ICompanyState
): IGetCompanyByOidSuccess => ({
  type: ECompanyTypes.GET_COMPANY_BY_OID_SUCCESS,
  payload,
})
export const getCompanyByOidFail = (
  payload: ICompanyFailurePayload
): IGetCompanyByOidFail => ({
  type: ECompanyTypes.GET_COMPANY_BY_OID_FAIL,
  payload,
})

//Get Company by oid
export const getCompaniesByUserRequest = (): IGetCompaniesByUserRequest => ({
  type: ECompanyTypes.GET_COMPANIES_BY_USER_REQUEST,
})
export const getCompaniesByUserSuccess = (
  payload: ICompanyAllResponse
): IGetCompaniesByUserSuccess => ({
  type: ECompanyTypes.GET_COMPANIES_BY_USER_SUCCESS,
  payload,
})
export const getCompaniesByUserFail = (
  payload: ICompanyFailurePayload
): IGetCompaniesByUserFail => ({
  type: ECompanyTypes.GET_COMPANIES_BY_USER_FAIL,
  payload,
})
