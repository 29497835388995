import { EBranchTypes } from 'store/actionTypes'
import { IBranchActions, IBranchDetail, IBranchState } from 'store/types'

const initialState: IBranchState = {
  loading: false,
  error: null,
  currentPage: null,
  dataLists: [],
  message: '',
  success: false,
  total: 0,
  branchDetail: null,
  allBranch: [],
}

export default (state = initialState, action: IBranchActions) => {
  switch (action.type) {
    //Get
    case EBranchTypes.GET_BRANCH_REQUEST:
      return { ...state, loading: true, error: null }
    case EBranchTypes.GET_BRANCH_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        // dataLists: action?.payload?.data?.dataLists || [],
        // success: true,
        loading: false,
      }
    case EBranchTypes.GET_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        dataLists: [],
        message: action.payload.error.message,
        error: action.payload.error,
      }
    //Post
    case EBranchTypes.POST_BRANCH_REQUEST:
      return { ...state, loading: true, error: null }
    case EBranchTypes.POST_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload.branch.message,
        success: action.payload.branch.success,
      }
    case EBranchTypes.POST_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message:
          action.payload.error.message[0] || action.payload.error.message,
        success: action.payload.error.success,
      }
    //Update
    case EBranchTypes.UPDATE_BRANCH_REQUEST:
      return { ...state, loading: true, error: null }
    case EBranchTypes.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.branch.message,
        success: action.payload.branch.success,
        dataLists: state.dataLists?.map((d: IBranchDetail) => {
          if (d.oid === action?.payload?.branch?.data?.oid) {
            return action?.payload?.branch?.data
          }
          return d
        }),
      }
    case EBranchTypes.UPDATE_BRANCH_FAIL:
      return { ...state, loading: false, error: action.payload.error }
    //Delete
    case EBranchTypes.DELETE_BRANCH_REQUEST:
      return { ...state, loading: true, error: null }
    case EBranchTypes.DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        total: state.total && state.total - 1,
        dataLists: state.dataLists?.filter(
          (data: IBranchDetail) => data.oid !== action?.payload?.branch?.oid
        ),
      }
    case EBranchTypes.DELETE_BRANCH_FAIL:
      return { ...state, loading: false, error: action.payload.error }
    case EBranchTypes.UPDATE_BRANCH_STATE_REQUEST:
      return { ...state }
    case EBranchTypes.UPDATE_BRANCH_STATE_SUCCESS:
      return { ...state, ...action.payload }
    //Get branch by oid
    case EBranchTypes.GET_BRANCH_BY_ID_REQUEST:
      return { ...state, loading: true }
    case EBranchTypes.GET_BRANCH_BY_ID_SUCCESS:
      return { ...state, branchDetail: action.payload.data[0], loading: false }
    case EBranchTypes.GET_BRANCH_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.error.message,
        loading: false,
      }
    case EBranchTypes.GET_BRANCH_ALL_REQUEST:
      return { ...state }
    case EBranchTypes.GET_BRANCH_ALL_SUCCESS:
      return { ...state, allBranch: action.payload.data }
    case EBranchTypes.GET_BRANCH_ALL_FAIL:
      return { ...state }
    default:
      return { ...state }
  }
}
