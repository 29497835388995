import { EMasterDataTypes } from 'store/actionTypes'
import { IMasterDataActions, IMasterDataState } from 'store/types'

const initialState: IMasterDataState = {
  loading: false,
  country: [],
  province: [],
  district: [],
  subDistrict: [],
  zipCode: [],
  error: null,
}

export default (state = initialState, action: IMasterDataActions) => {
  //   console.log(`MasterData :: ${action.type}`, { state, action })
  switch (action.type) {
    //Country
    case EMasterDataTypes.GET_COUNTRY_REQUEST:
      return { ...state, loading: true, error: null }
    case EMasterDataTypes.GET_COUNTRY_SUCCESS:
      return { ...state, country: action.payload.country || [], loading: false }
    case EMasterDataTypes.GET_COUNTRY_FAIL:
      return { ...state, loading: false, error: action.payload.error }
    //Province
    case EMasterDataTypes.GET_PROVINCE_REQUEST:
      return { ...state, loading: true, error: null }
    case EMasterDataTypes.GET_PROVINCE_SUCCESS:
      return {
        ...state,
        province: action.payload.province || [],
        loading: false,
      }
    case EMasterDataTypes.GET_PROVINCE_FAIL:
      return { ...state, loading: false, error: action.payload.error }
    //District
    case EMasterDataTypes.GET_DISTRICT_REQUEST:
      return { ...state, loading: true, error: null }
    case EMasterDataTypes.GET_DISTRICT_SUCCESS:
      return {
        ...state,
        district: action.payload.district || [],
        loading: false,
      }
    case EMasterDataTypes.GET_DISTRICT_FAIL:
      return { ...state, loading: false, error: null }
    //Sub District
    case EMasterDataTypes.GET_SUB_DISTRICT_REQUEST:
      return { ...state, loading: true, error: null }
    case EMasterDataTypes.GET_SUB_DISTRICT_SUCCESS:
      return {
        ...state,
        subDistrict: action.payload.subDistrict || [],
        loading: false,
      }
    case EMasterDataTypes.GET_SUB_DISTRICT_FAIL:
      return { ...state, loading: false, error: null }
    //ZipCode
    case EMasterDataTypes.GET_ZIP_CODE_REQUEST:
      return { ...state, loading: true, error: null }
    case EMasterDataTypes.GET_ZIP_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        zipCode: action.payload.data,
      }
    case EMasterDataTypes.GET_ZIP_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        message: action.payload.error.message,
      }
    default:
      return { ...state }
  }
}
