import { EBranchGroupTypes } from 'store/actionTypes'

export const getBranchGroupRequest = (payload: any): any => ({
  type: EBranchGroupTypes.GET_BRANCH_GROUP_REQUEST,
  payload,
})

export const getBranchGroupSuccess = (payload: any) => ({
  type: EBranchGroupTypes.GET_BRANCH_GROUP_SUCCESS,
  payload,
})

export const getBranchGroupFail = (payload: any) => ({
  type: EBranchGroupTypes.GET_BRANCH_GROUP_FAIL,
  payload,
})

export const postBranchGroupRequest = (payload: any) => ({
  type: EBranchGroupTypes.POST_BRANCH_GROUP_REQUEST,
  payload,
})
export const postBranchGroupSuccess = (payload: any) => ({
  type: EBranchGroupTypes.POST_BRANCH_GROUP_SUCCESS,
  payload,
})
export const postBranchGroupFail = (payload: any) => ({
  type: EBranchGroupTypes.POST_BRANCH_GROUP_FAIL,
  payload,
})

export const getEditBranchGroupRequest = (payload: any) => ({
  type: EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_REQUEST,
  payload,
})
export const getEditBranchGroupSuccess = (payload: any) => ({
  type: EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_SUCCESS,
  payload,
})
export const getEditBranchGroupFail = (payload: any) => ({
  type: EBranchGroupTypes.GET_EDIT_BRANCH_GROUP_FAIL,
  payload,
})

export const deleteBranchGroupRequest = (payload: any) => ({
  type: EBranchGroupTypes.DELETE_BRANCH_GROUP_REQUEST,
  payload,
})
export const deleteBranchGroupSuccess = (payload: any) => ({
  type: EBranchGroupTypes.DELETE_BRANCH_GROUP_SUCCESS,
  payload,
})
export const deleteBranchGroupFail = (payload: any) => ({
  type: EBranchGroupTypes.DELETE_BRANCH_GROUP_FAIL,
  payload,
})

export const updateBranchGroupStateRequest = (payload: any) => ({
  type: EBranchGroupTypes.UPDATE_BRANCH_GROUP_STATE_REQUEST,
  payload,
})
export const updateBranchGroupStateSuccess = (payload: any) => ({
  type: EBranchGroupTypes.UPDATE_BRANCH_GROUP_STATE_SUCCESS,
  payload,
})
