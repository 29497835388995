import { all, put, takeLatest, select } from 'redux-saga/effects'
import requestManagement from 'store/actions/requestManagement'
import service from 'store/service'
import { ISiteAppState } from 'store/types'
import { EMenuPermissionTypes } from 'store/actionTypes'
import {
  setMenuPermission,
  setSiteApplicationByCompany,
  updateMenuPermissionSuccess,
  setChartSetting,
  updateMenuPermissionFailed,
} from 'store/actions'
const { methodGET, methodPOST } = requestManagement

interface ResponseMenuPermission {
  success: boolean
  message: string
  data: []
}

function* FetchMenuPermission() {
  try {
    const response: ResponseMenuPermission = yield methodGET({
      url: service.apiMenuPermission,
    })
    yield put(setMenuPermission(response.data))
  } catch (e) {}
}

function* updateSiteApplication({ payload }: any) {
  const state: ISiteAppState = yield select(
    (root: any) => root.siteapplication.selectSiteApp
  )

  const objData = {
    company_permission: state,
  }

  try {
    const response: ResponseMenuPermission = yield methodPOST({
      url: service.apiSaveSiteApp,
      data: objData,
    })
    // console.log('response', response);
    if (response.success) {
      yield put(updateMenuPermissionSuccess(response.success))
    }
  } catch (e) {}
}

function* fetchMenuPermissionByCompany({ payload }: any) {
  if (payload !== undefined) {
    try {
      const response: ResponseMenuPermission = yield methodGET({
        url: service.apiGetSiteAppByCompany.replace(':oid', payload),
      })
      // console.log('fetchMenuPermissionByCompany', response);

      if (response.data !== null) {
        yield put(setSiteApplicationByCompany(response.data))
      } else {
        yield put(setSiteApplicationByCompany([]))
      }
    } catch (e) {}
  }
}

function* updateChartSetting({ payload }: any) {
  if (payload !== undefined) {
    try {
      const response: ResponseMenuPermission = yield methodPOST({
        url: service.apiChartSetting,
        data: payload,
      })
      if (response.success) {
        yield put(updateMenuPermissionSuccess(response.success))
      } else {
        yield put(updateMenuPermissionFailed(!response.success))
      }
    } catch (e) {}
  }
}

function* fetchChartSetting({ payload }: any) {
  try {
    const response: ResponseMenuPermission = yield methodGET({
      url: service.apiChartSettingByCompany.replace(':oid', payload),
    })
    if (response.success) {
      // yield put(updateMenuPermissionSuccess(response.success))
      yield put(setChartSetting(response.data))
    }
  } catch (e) {}
}
function* siteAppSaga() {
  yield all([
    takeLatest(EMenuPermissionTypes.FETCH_MENU_PERMISSION, FetchMenuPermission),
  ])
  yield all([
    takeLatest(
      EMenuPermissionTypes.UPDATE_SITE_APPLICATION,
      updateSiteApplication
    ),
  ])
  yield all([
    takeLatest(
      EMenuPermissionTypes.FETCH_SITE_APPLICATION,
      fetchMenuPermissionByCompany
    ),
  ])
  yield all([
    takeLatest(EMenuPermissionTypes.UPDATE_CHART_SETTING, updateChartSetting),
  ])
  yield all([
    takeLatest(EMenuPermissionTypes.FETCH_CHART_SETTING, fetchChartSetting),
  ])
}
export default siteAppSaga
