import { all, put, takeLatest } from 'redux-saga/effects'
import requestManagement from 'store/actions/requestManagement'
import { EUserTypes } from 'store/actionTypes'
import service from 'store/service'
const { methodGET, methodPOST } = requestManagement
import {
  setErrorEmployee,
  listRoleByCompany,
  fetchUserSuccess,
  fetchUserByIdSuccess,
  deleteUserSuccess,
  listAllRoleInUser,
  addUserSuccess,
  editUserFailed,
} from 'store/actions'
import { IUserParams } from 'store/types'
import { Helper } from 'utils/helper'

interface ResponseUser {
  success: boolean
  message: string
  data: [] | object
}

function* fetchUser({ payload }: any) {
  try {
    const response: ResponseUser = yield methodGET({
      url: service.apiUsers,
      param: payload,
    })
    yield put(fetchUserSuccess(response.data))
  } catch (e) {}
}

function* fetchUserByCompany({ payload }: any) {
  if (payload.oid !== undefined) {
    try {
      const response: ResponseUser = yield methodGET({
        url: service.apiUserByCompany.replace(':oid', payload.oid),
        param: payload,
      })
      yield put(fetchUserSuccess(response.data))

      // console.log('response', response);
    } catch (e) {}
  }
}

function* fetchUserById({ payload }: any) {
  if (payload !== undefined) {
    try {
      const response: ResponseUser = yield methodGET({
        url: service.apiGetUsers.replace(':oid', payload),
        // param: payload
      })
      yield put(fetchUserByIdSuccess(response.data))
    } catch (e) {}
  }
}
function* addNewUser({ payload }: any) {
  const objData = {
    email: payload.email,
    username: payload.username,
    employee_code: payload.employeeId
      ? Helper.cryptoUtil(payload.employeeId)
      : null,
    password: Helper.cryptoUtil(payload.password),
    secure_password: Helper.cryptoUtil(payload.oldpin),
    account_informations: {
      first_name: payload.firstname,
      last_name: payload.lastname,
    },
    account_roles: {
      roles: {
        oid:
          typeof payload.role === 'object' ? payload.role.value : payload.role,
      },
    },
    companies: payload.companies,
  }

  try {
    const response: ResponseUser = yield methodPOST({
      url: service.apiUsers,
      data: objData,
      // param: payload
    })
    if (response.success) {
      yield put(
        addUserSuccess({
          mode: 'Add',
          success: response.success,
        })
      )
    } else {
      yield put(editUserFailed(response.message))
    }
  } catch (e) {}
}

function* editUsers({ payload }: any) {
  let objData: any = {}
  const localEmail = localStorage.getItem('email')
  objData = {
    email: payload.email,
    username: payload.username,
    // employee_code: payload.employeeId ? Helper.cryptoUtil(payload.employeeId) : null,
    // password: Helper.cryptoUtil(payload.newpassword),
    // secure_password: Helper.cryptoUtil(payload.pin),
    account_informations: {
      first_name: payload.firstname,
      last_name: payload.lastname,
    },
    account_roles: {
      roles: {
        oid:
          typeof payload.role === 'object' ? payload.role.value : payload.role,
      },
    },
    companies: payload.companies,
  }
  if (payload.newpassword) {
    objData['password'] = Helper.cryptoUtil(payload.newpassword)
  }

  if (payload.secure_password) {
    objData['secure_password'] = Helper.cryptoUtil(payload.secure_password)
  }

  if (localEmail === payload.email && payload.oldpassword) {
    objData['old_password'] = Helper.cryptoUtil(payload.oldpassword)
  }

  if (localEmail === payload.email && payload.oldpin) {
    objData['old_secure_password'] = Helper.cryptoUtil(payload.oldpin)
  }
  if (payload.employeeId) {
    objData['employee_code'] = Helper.cryptoUtil(payload.employeeId)
  }

  // console.log('editUsers', payload, objData);
  if (payload.oid !== undefined) {
    try {
      const response: ResponseUser = yield methodPOST({
        url: service.apiUserUpdate.replace(':oid', payload.oid),
        data: objData,
        // param: payload
      })
      if (response.success) {
        yield put(
          addUserSuccess({
            mode: 'Edit',
            success: response.success,
          })
        )
      } else {
        yield put(editUserFailed(response.message))
      }
    } catch (e) {}
  }
}

function* deleteUser({ payload }: any) {
  if (payload !== undefined) {
    try {
      const response: ResponseUser = yield methodPOST({
        url: service.apiUserDelete.replace(':oid', payload),
        // param: payload
      })
      if (response.success) {
        yield put(deleteUserSuccess(true))
      }
    } catch (e) {}
  }
}

function* checkEmployee({ payload }: any) {
  // console.log('checkEmployee', payload)
  try {
    const response: ResponseUser = yield methodPOST({
      url: service.apiEmployeeID,
      data: {
        employee_code: Helper.cryptoUtil(payload),
      },
    })

    yield put(setErrorEmployee(response.success))
    // console.log('fetchMenuPermission', response);
    // yield put(checkEmployee(response.data))
  } catch (e) {}
}

function* fetchRoleByCompanies({ payload }: any) {
  if (payload !== undefined) {
    try {
      const response: ResponseUser = yield methodGET({
        url: service.apiRoleByCompany.replace(':oid', payload),
      })
      // console.log('payload', response);

      yield put(listRoleByCompany(response.data))
    } catch (e) {}
  } else {
    try {
      const response: ResponseUser = yield methodGET({
        url: service.apiAllRole,
      })
      yield put(listAllRoleInUser(response.data))
    } catch (e) {}
  }
}

function* userSaga() {
  yield all([takeLatest(EUserTypes.FETCH_LIST_USER, fetchUser)])
  yield all([
    takeLatest(EUserTypes.FETCH_LIST_USER_BY_COMPANY, fetchUserByCompany),
  ])

  yield all([takeLatest(EUserTypes.FETCH_USER_BY_ID, fetchUserById)])
  yield all([takeLatest(EUserTypes.ADD_USER_REQUEST, addNewUser)])
  yield all([takeLatest(EUserTypes.UPDATE_USER_REQUEST, editUsers)])
  yield all([takeLatest(EUserTypes.DELETE_USER_REQUEST, deleteUser)])
  yield all([takeLatest(EUserTypes.CHECK_EMPLOYEE_ID, checkEmployee)])
  yield all([
    takeLatest(EUserTypes.FETCH_ROLE_BY_COMPANY, fetchRoleByCompanies),
  ])
}
export default userSaga
